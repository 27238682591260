/* LandingPage.css */
.landing-page-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  min-height: 800px;
  max-height: 800px;
  width: 100%;
  /* background-color: #f5f5f5; */
  background-color: white;
  overflow: hidden;
  /* border-bottom: solid #e0674f 3px; */
}

.circle-images-container {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Prevent interaction with the circles */
  z-index: 0; /* Ensure circles are behind the content */
}

.circle-image {
  width: 200px; /* Default size, adjust as needed */
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  background-color: #cccccc; /* Placeholder background color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #666;
  transition: transform 0.3s ease; /* Smooth scaling */
}

.circle-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Positioning and resizing */
.circle-image:nth-child(1) {
  width: 24rem;
  height: 24rem;
  top: -8%;
  left: -5%;
}
.circle-image:nth-child(2) {
  width: 18rem;
  height: 18rem;
  top: 8%;
  left: 20%;
}
.circle-image:nth-child(3) {
  width: 18rem;
  height: 18rem;
  top: 8%;
  right: 20%;
}
.circle-image:nth-child(4) {
  width: 24rem;
  height: 24rem;
  top: -8%;
  right: -5%;
}
.circle-image:nth-child(5) {
  width: 20rem;
  height: 20rem;
  top: 0%;
  left: calc(50% - 10rem);
  right: calc(50% - 10rem);
}
.circle-image:nth-child(6) {
  width: 20rem;
  height: 20rem;
  top: calc(55% - 10rem);
  bottom: calc(45% - 10rem);
  left: -5%;
}
.circle-image:nth-child(7) {
  width: 20rem;
  height: 20rem;
  top: calc(55% - 10rem);
  bottom: calc(45% - 10rem);
  right: -5%;
}
.circle-image:nth-child(8) {
  width: 20rem;
  height: 20rem;
  bottom: -8%;
  left: -5%;
}
.circle-image:nth-child(9) {
  width: 15rem;
  height: 15rem;
  bottom: 10%;
  left: 20%;
}

.circle-image:nth-child(10) {
  width: 15rem;
  height: 15rem;
  bottom: 10%;
  right: 20%;
}
.circle-image:nth-child(11) {
  width: 20rem;
  height: 20rem;
  bottom: -8%;
  right: -5%;
}
.circle-image:nth-child(12) {
  width: 30rem;
  height: 30rem;
  bottom: -15%;
  left: calc(50% - 15rem);
  right: calc(50% - 15rem);
}

.content-container {
  position: relative;
  text-align: center;
  /* background: #fff; */
  min-width: 50%;
  padding: 40px 60px;
  /* border-radius: 8px; */
  /* box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); */
  z-index: 1; /* Ensure it's above the images */
}

.main-heading {
  font-size: 5rem; /* Larger heading size */
  margin: 0;
  color: #333;
}

.sub-heading {
  font-size: 2rem;
  color: #777;
  margin: 2rem 0px 2rem;
}

.cta-button {
  padding: 12px 25px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #ff7a5c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #e0674f;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .landing-page-container {
    height: 55vh; /* Allow the container to adjust height */
    padding: 20px;
    max-height: 55vh;
    min-height: 55vh;
  }

  .circle-image {
    width: 8rem; /* Smaller size for mobile */
    height: 8rem;
  }

  /* Adjust positions for mobile */
  .circle-image:nth-child(1) {
    width: 12rem;
    height: 12rem;
    top: -2%;
    left: -5%;
  }
  .circle-image:nth-child(2) {
    width: 8rem;
    height: 8rem;
    top: 22%;
    left: 10%;
  }
  .circle-image:nth-child(3) {
    width: 8rem;
    height: 8rem;
    top: 22%;
    right: 10%;
  }
  .circle-image:nth-child(4) {
    width: 12rem;
    height: 12rem;
    top: -2%;
    right: -5%;
  }
  .circle-image:nth-child(5) {
    width: 18rem;
    height: 18rem;
    top: 1%;
    left: calc(50% - 9rem);
    right: calc(50% - 9rem);
  }
  .circle-image:nth-child(6) {
    width: 12rem;
    height: 12rem;
    top: 50%;
    left: -3%;
  }
  .circle-image:nth-child(7) {
    width: 12rem;
    height: 12rem;
    top: 50%;
    right: -3%;
  }
  .circle-image:nth-child(8) {
    width: 12rem;
    height: 12rem;
    bottom: 1%;
    left: 0%;
  }
  .circle-image:nth-child(9) {
    width: 8rem;
    height: 8rem;
    bottom: 18%;
    left: 25%;
  }
  .circle-image:nth-child(10) {
    width: 12rem;
    height: 12rem;
    bottom: 1%;
    right: 0%;
  }
  .circle-image:nth-child(11) {
    width: 8rem;
    height: 8rem;
    bottom: 18%;
    right: 25%;
  }
  .circle-image:nth-child(12) {
    width: 18rem;
    height: 18rem;
    bottom: -10%;
    left: calc(50% - 9rem);
    right: calc(50% - 9rem);
  }

  .content-container {
    padding: 20px;
    min-width: 100%;
  }

  .main-heading {
    font-size: 3rem; /* Reduce heading size for mobile */
  }

  .sub-heading {
    font-size: 1.5rem; /* Reduce sub-heading size */
    margin: 1rem 0 2.5rem;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}
