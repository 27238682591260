.main {
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: column;
}

.text-div {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  /* emma */
  margin-top: 1%;
}

.rec-plat-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 4%;
  font-size: 3em;
}
