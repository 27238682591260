body,
html {
  background-color: white;
}

.gradient-banner {
  width: 100%;
  height: 5dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background-color: white;
}

/* Row 1 */
.banner-row {
  width: 100%;
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, transparent, #f5c3b3, transparent);
  font-family: "Inria Serif", serif;
}

/* Text */
.gradient-banner-text {
  font-size: 2em;
}
