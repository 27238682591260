.WellnessStoryTest {
  flex: 1;
  width: 100%;
  max-width: 1024px;
  margin: 96px auto;
  padding: 16px;
  font-family: 'Avantt Regular';
  box-sizing: border-box;
}

.WellnessStoryTest * {
  font-family: 'Avantt Regular';
  box-sizing: border-box;
}
