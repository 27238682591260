.IsraelHome-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 750px) {
    padding-top: 12%;
  }
}

.IsraelHome-homepage {
  box-sizing: border-box;
}

.IsraelHome-supportIsrael {
  position: relative;
  text-align: center;
  color: white;
}

.IsraelHome-supportIsrael img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.IsraelHome-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}


.IsraelHome-signUp {
  text-align: center;
  margin: 5% 10%;
  font-size: 36px;

  @media screen and (max-width: 750px) {
    font-size: 28px;
  }
}

.IsraelHome-loginContainer {
  margin-top: 20px;
}
