@import "../styles/variables";
@import "../styles/theme";

.wellness_homepage {
  display: flex;
  flex-direction: column;
  background-color: var(--background-transparent);
  font-family: $avantt-regular;
  width: 100%;
  max-width: 100%;

  .redefine {
    font-size: $title_sm;
    font-family: $avantt-light;
    color: var(--h-color);
    .redefineTitle {
      margin-top: 5rem;
    }
  }

  .hero_wellness {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;

    .cta_button {
      margin-top: 4vh;
      margin-bottom: 5vh;
      @media screen and (max-width: 992px) {
        margin-top: 10%;
        margin-bottom: 0;
      }
    }

    .login-container {
      display: flex;
      flex-direction: column;
    }

    .hero_right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 65%;
      padding-left: 6vw;
      // padding-top: 5vh;
      padding-bottom: 0vh;
      @media screen and (max-width: 992px) {
        width: 100%;
        padding-top: 2vh;
        padding-bottom: 2vh;
        padding-left: 0vh;
        padding-right: 0vh;
        position: relative;
        z-index: 2;
        height: 600px;
      }

      &_header {
        max-width: 90%;
        flex: 1;
        font-family: $avantt-bold;
        font-style: normal;
        font-weight: 700;
        font-size: 54px;
        line-height: 65px;
        text-decoration-line: none;
        padding-top: 12.5%;
        margin-bottom: 0;
        padding-bottom: 0;

        @media screen and (max-width: 800px) {
          max-width: 90%;
          margin-top: 5vh;
          font-size: $title_md;
          line-height: $title_lg;
          padding-top: 0;
        }

        .flex {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .transition-0 {
          display: flex;
          width: 130px;
        }

        .transition-1 {
          display: flex;
          width: 150px;
        }

        .transition-2 {
          display: flex;
          width: 185px;
        }
        .transition-3 {
          display: flex;
          width: 230px;
        }
        .transition-4 {
          display: flex;
          width: 215px;
        }

        span {
          text-decoration-line: underline;

          .period {
            color: var(--accent);
            text-decoration-line: none;
          }
        }
        @keyframes fadeInFromTopPart1 {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fadeInFromTopPart2 {
          from {
            opacity: 0;
            transform: translateY(-10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .title {
          display: flex;
          flex-direction: column;
          font-size: $hero_title;
          line-height: 90px;
          text-decoration-line: none;
          overflow-wrap: break-word;

          @media screen and (max-width: 800px) {
            font-size: 40px;
            line-height: 60px;
          }

          .part1 {
            opacity: 0;
            animation: fadeInFromTopPart1 1s forwards;
            animation-delay: 0.5s; // This will make it appear first
            text-decoration-line: none;
          }

          .part2 {
            opacity: 0;
            animation: fadeInFromTopPart2 1.2s forwards;
            animation-delay: 1s; // This delay ensures it comes in after the first part
            text-decoration-line: none;
          }
        }
      }

      &_action {
        max-width: 90%;
        flex: 1;
        font-family: $avantt-bold;
        font-style: normal;
        text-decoration-line: none;
        text-align: left;

        h2 {
          font-size: 36px;
          text-align: left;
          margin: 0;
          @media screen and (max-width: 800px) {
            font-size: $text_lg;
            line-height: $text_lg;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        h2 span {
          display: block; /* To keep each line on its own row. */
          opacity: 0; /* Start hidden. */
          animation: fadeIn 1s forwards; /* Use the fadeIn animation, lasting 1s, and remain in the ending state once done. */
        }

        h2 span:nth-child(1) {
          animation-delay: 0.5s; /* Starts after half a second. */
          text-indent: 0em; /* No indent */
        }

        h2 span:nth-child(2) {
          animation-delay: 1.5s; /* Starts after 1 second. */
          text-indent: 1em; /* Indentation by 1em */
        }

        h2 span:nth-child(3) {
          animation-delay: 2s;
          text-indent: 2em; /* Indentation by 1em */
        }

        h2 span:nth-child(4) {
          animation-delay: 2.5s;
          text-indent: 3em; /* Indentation by 1em */
        }

        h2 span:nth-child(5) {
          animation-delay: 3s;
          text-indent: 4.75em; /* Indentation by 1em */
        }

        h2 span:nth-child(6) {
          animation-delay: 3.5s;
          text-indent: 5.75em; /* Indentation by 1em */
        }

        h2 span:nth-child(7) {
          animation-delay: 4s;
          text-indent: 6em; /* Indentation by 1em */
        }

        @media screen and (max-width: 800px) {
          max-width: 90%;
          margin-top: 5vh;
          font-size: $title_md;
          line-height: $title_lg;
        }

        .flex {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
      }

      &_btn {
        padding: 11px 28px;
        font-family: $avantt-regular;
        font-size: $text_md;
        line-height: 24px;
        text-align: center;
        color: $white;
        background: var(--primary);
        border-radius: 30px;
        @media screen and (max-width: 800px) {
          margin-top: 10%;
          margin-bottom: 0vh;
          max-width: 40%;
        }
      }

      .subtitle {
        // margin-top: 30px;
        font-size: $text_md;
        margin-bottom: 12px;
        @media screen and (max-width: 800px) {
          font-family: "Inter Regular";
          margin-bottom: 5rem;
        }
      }

      .question {
        margin-top: 10px;
        font-size: $text_sm;
        .bold {
          font-family: "Avantt Bold";
          color: var(--h-color, var(--accent));
        }
      }
    }
    .image-group {
      height: 700px;
      max-height: 700px;
      max-width: 50%;

      @media (max-width: 900px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: 600px;
        min-height: 600px;
        opacity: 60%;
        z-index: 1; /* Places the image behind the text */
      }
    }
    img {
      width: 100%;
      height: 100%;

      @media screen and (max-width: 900px) {
        height: 750px;
        h3 {
          font-family: $inter-bold;
        }
      }
    }

    @media screen and (max-width: 1000px) {
      padding-left: 5vw;
      padding-top: 10vh;
      padding-bottom: 5vh;
    }
  }

  // .screening {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   padding: 10vh 5vw;
  //   background-color: white;
  //   color: var(--light-accent);
  //   text-align: center;

  //   .title {
  //     display: inline-block;
  //     font-size: $title_lg;
  //     font-family: $avantt-bold;
  //     color: var(--primary);
  //     vertical-align: middle; /* For vertical alignment */
  //     line-height: $title_lg;

  //     @media screen and (max-width: 900px) {
  //       font-size: $title_sm;
  //     }
  //   }

  //   .flex {
  //     display: inline-block;
  //     vertical-align: middle; /* For vertical alignment */
  //     width: 40rem;

  //     text-align: left; /* Ensures left alignment */
  //     overflow: hidden;
  //   }

  //   .transition {
  //     display: inline-block;
  //     font-size: $title_lg;
  //     font-family: $avantt-bold;
  //     color: var(--primary);
  //     text-decoration: underline;
  //     white-space: nowrap;
  //     vertical-align: middle; /* For vertical alignment */

  //     @media screen and (max-width: 900px) {
  //       font-size: $title_sm;
  //     }
  //   }

  //   @media screen and (max-width: 1150px) {
  //     padding: 5vw;
  //   }
  // }
  .screening {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10vh 5vw;
    background-color: white;
    color: var(--light-accent);
    text-align: center;

    .title {
      font-size: $title_lg;
      font-family: $avantt-regular;
      color: var(--primary);
      line-height: 1.2;
      margin-bottom: 3rem;
      position: relative; // This ensures the absolute positioned text remains within this boundary.

      @media screen and (max-width: 900px) {
        font-size: $title_sm;
      }
    }

    .word-container {
      display: inline-block;
      width: 100%;
      left: 1rem;
      max-width: 40rem; // Adjust as needed.
      height: auto; // Adjust based on line-height.
      position: relative;
      font-family: $avantt-thin-italic;
      overflow: visible; // This ensures that the transitioning text is cropped and doesn't overflow.
      padding-bottom: 0.5rem;
      vertical-align: baseline; /* Make sure the text inside aligns with surrounding text */
    }

    .transition {
      position: absolute;
      top: -4rem;
      left: 0;
      text-decoration: underline;
      white-space: nowrap;
      line-height: 1.4;
      text-align: center;
    }

    .columns {
      margin-top: 4vh;
      display: flex;
      justify-content: center; /* This will center the columns */
      margin-left: 15%; /* to center text */
      width: 100%; /* Take up the full width available */

      .col {
        flex: 0 0 calc(50% - 20px); /* This allows two columns and accounts for some margin */
        max-width: 400px;
        font-family: $italiana-regular;
        font-size: $text_sm;
        color: var(--accent);
        line-height: 36px;
        text-align: left; /* Left-aligns the text within the column */
        padding: 0 20px; /* Some horizontal padding for spacing */
        margin: 0 10px; /* Some horizontal margin for spacing between columns */
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .columns-stats {
      margin-top: 10vh;
      column-gap: 8vh;
      gap: 3vh;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      .col-stats {
        max-width: 300px;
        height: 350px;
        gap: 3rem;
        width: 90%;
        text-align: center;
        font-family: $italiana-regular;
        color: var(--primary);
        font-size: $text_sm;
        justify-content: center;
        align-items: center;
        line-height: 36px;
        border-radius: 10%;
        padding: 2%;
        box-shadow: 0 0 0 10px var(--accent-light),
          /* This covers the main box */ 0 0 5px 10px rgba(0, 0, 0, 0.2); /* This creates the shadow */
        @media screen and (max-width: 900px) {
          max-width: 90%;
          border-radius: 0;
          padding: 0;
          box-shadow: none;
          height: auto;
        }
      }
      h2 {
        font-family: $avantt-bold;
        color: var(--h-color);
      }
      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
    }

    @media screen and (max-width: 800px) {
      .screening .title .word-container {
        font-size: $title_md; // Assuming you have a medium font-size defined, or specify a size in rem/px
        text-align: center;
        width: 100%;
      }
      .transition {
        font-size: $title_sm;
        top: -2rem;
        left: 50%;
        transform: translateX(-50%);
      }

      .col {
        flex-basis: 100%;
        padding: 0;
        margin: 0 0 40px 0;
        text-align: left;
      }
      .columns {
        align-items: flex-start; // This will left align the columns.
      }
    }

    @media screen and (max-width: 1150px) {
      .screening {
        padding: 5vw;
      }
    }
  }

  .create_wellbeing {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    padding: 10vh 5vw 4vh;
    // background-color: var(--primary);
    background-color: var(--accent-light);
    color: var(--primary);
    text-align: center;

    .title {
      font-size: $title_lg;
      font-family: $avantt-bold;
      color: var(--primary);

      @media screen and (max-width: 900px) {
        font-size: $title_sm;
      }
    }

    .recommended {
      font-size: $title_sm;
      font-family: $avantt-regular;
      color: var(--primary);
      line-height: 1.2;
      margin-top: 10rem;
      position: relative; // This ensures the absolute positioned text remains within this boundary.
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px; /* Adjust for desired spacing between images */
      .recommended img {
        flex: 1;
        max-width: 100%;
        height: auto;
      }

      .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 4rem;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
      }
      .aap {
        width: 33rem;
        height: 19rem;
      }
      .usptf {
        width: 38rem;
        height: 14rem;
      }
      .acog {
        width: 30rem;
        height: 10rem;
      }
      .aacap {
        width: 24rem;
        height: 10rem;
      }
      .cha {
        width: 30rem;
        height: 10rem;
      }

      @media screen and (max-width: 900px) {
        font-size: $title_sm;
        margin-top: 5rem;
      }
    }

    h1 {
      font-family: $avantt-regular !important;
      color: var(--primary);
      @media screen and (max-width: 900px) {
        font-size: $title_sm;
        margin-top: 2vh;
        margin-bottom: 2vh;
      }
    }

    h2 {
      font-size: $title_sm;
      font-family: $avantt-bold;
      color: var(--h-color);
    }

    .bold {
      font-family: $avantt-bold;
      color: var(--primary);
    }

    .box {
      margin-top: 80px;
      height: 200px;
      max-width: 1200px;
      width: 100%;
    }

    .columns {
      margin-top: 4vh;
      column-gap: 8vh;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .col {
        max-width: 400px;
        width: 100%;
        text-align: center;
        font-family: $italiana-regular;
        font-size: $text_sm;
        line-height: 36px;
        border-radius: 10%;
        padding: 2%;
        box-shadow: 0 0 0 10px var(--accent-light),
          /* This covers the main box */ 0 0 5px 10px rgba(0, 0, 0, 0.2); /* This creates the shadow */

        h2 {
          font-size: $title_lg;
          margin: 50px 20px;
        }

        @media screen and (max-width: 700px) {
          max-width: 100%;
          margin-bottom: 40px;
          text-align: center;
        }
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
      }
    }

    @media screen and (max-width: 900px) {
      padding: 5vh;
    }
  }

  .banner {
    display: flex;
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 2rem;
    justify-content: center;
    box-sizing: border-box;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      max-width: 850px;
      width: 100%;
      text-align: center;
      color: var(--primary);
      font-family: $avantt-bold;

      span {
        text-decoration-line: underline;
        text-underline-offset: 12px;
        font-style: italic;

        .period {
          color: var(--light-accent);
          text-decoration-line: none;
        }
      }

      div {
        margin: 20px;
      }

      h2 {
        font-size: $title_md;
        margin-top: 0;
        margin-bottom: 28px;
        @media screen and (max-width: 800px) {
          font-size: $text_lg;
        }
      }

      h3 {
        @media screen and (max-width: 800px) {
          font-size: $text_md;
        }
      }

      p {
        font-size: $text_md;
        font-family: $avantt-regular;
      }
    }

    @media screen and (max-width: 800px) {
      padding: 5vh 5vw;
    }
  }

  .newHero {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #f2ece9;
    position: relative;

    @media screen and (max-width: 992px) {
      margin-top: 50px;
      width: 100%;
    }

    .hero_subtitle {
      z-index: 100;
      position: absolute;
      top: 600px;
      font-size: 2.25rem;
      font-family: $inter-bold;
      padding-left: 6rem;
      color: #1e3f6d;
      @media screen and (max-width: 1800px) {
        top: 475px;
      }
      @media screen and (max-width: 1600px) {
        font-size: 1.75rem;
        top: 440px;
      }
      @media screen and (max-width: 1400px) {
        top: 370px;
      }
      @media screen and (max-width: 1200px) {
        top: 320px;
        font-size: 1.25rem;
      }
      @media screen and (max-width: 1000px) {
        top: 200px;
      }

      @media screen and (max-width: 992px) {
        font-size: 1.25rem;
        top: 350px;
      }
    }

    .hero_buttons_container {
      top: 640px;
      z-index: 100;
      position: absolute;
      display: flex;
      flex-direction: column;
      color: rgb(242, 236, 233) !important;
      border: none;
      font-size: 1.5rem;
      margin-top: 2rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      @media screen and (max-width: 1800px) {
        top: 525px;
      }
      @media screen and (max-width: 1600px) {
        top: 480px;
      }
      @media screen and (max-width: 1400px) {
        top: 420px;
      }
      @media screen and (max-width: 1200px) {
        top: 350px;
        font-size: 1.25rem;
        // margin-left: 5rem;
      }
    }

    .hero_buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: baseline;
      gap: 3rem;
      // padding: 1.25rem 30px;
      border-radius: 40px;

      @media screen and (max-width: 502px) {
        margin-top: 2rem;
        flex-direction: row;
        align-items: center;
        top: 310px;
        gap: 2rem;
        padding: none;
      }

      &:hover {
        background-color: lighten(orange, 10%);
        color: white;
        font-family: $avantt-bold;
        height: 51px;
      }
    }
  }

  .hero_button {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1rem;
  }

  .hero_buttons_subtitles_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap: 3rem;
    font-size: 1.05rem;
    border-radius: 40px;
    margin-top: 3rem;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
      flex-direction: row;
      align-items: baseline;
      justify-content: center;
      width: 100%;
      top: 310px;
      gap: 3rem;
      padding: none;
    }
  }
  .hero_buttons_subtitles {
    color: var(--primary) !important;
    text-align: center;
    line-height: 1.4rem;
    width: 208px;
    @media screen and (max-width: 768px) {
      width: 15rem;
    }
    &:hover {
      background-color: none;
    }
  }
  .underline {
    text-decoration: underline;
  }

  .secondSlide {
    display: flex;
    justify-content: center;
    flex-direction: row;
    // align-items: center;
    width: 100%;
    background-color: white;
    position: relative;
    height: 700px;

    .text {
      z-index: 100;
      position: absolute;
      top: 15;
    }
  }

  .monthly_wellness {
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 2720px; // Replace with the actual value you want
    // right: 20px; // Replace with the actual value you want
    color: white !important;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      // background-color: lighten(orange, 10%); // replace with actual color code
    }
  }

  .monthly_wellness_image {
    max-width: 65%;
    min-width: 800px;

    &:hover {
      max-width: 85%;
      // background-color: lighten(orange, 10%); // replace with actual color code
    }
  }

  .everyJourney {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
  .checkup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    background-color: #f2ece9;
    position: relative;

    .checkup_button {
      z-index: 100;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      bottom: 6rem; // Replace with the actual value you want
      // right: 20px; // Replace with the actual value you want
      background-color: #ea5f26;
      color: white !important;
      border: none;
      padding: 0.5rem 1rem;
      font-size: 24px !important;
      border-radius: 40px;
      min-width: 10rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: lighten(
          orange,
          10%
        ); // replace with actual color code
      }
    }
  }

  .boxes {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .priority {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: F7F5F4;
  }

  .insights {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10vh 20vw;
    width: 100%;
    box-sizing: border-box;

    .tabs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

      h3 {
        margin-bottom: 20px;
        font-size: $text_md;
      }

      .selector {
        margin-bottom: 20px;
        background-color: transparent;
        border: none;
        font-size: $title_md;
        text-align: left;
        cursor: pointer;
        @media screen and (max-width: 800px) {
          font-size: 32px;
        }
      }
      .active {
        color: var(--primary);
      }

      a {
        margin-top: 40px;
        background-color: var(--primary);
        padding: 11px 28px;
        color: $white;
        border-radius: 30px;
        font-weight: bold;
      }
    }

    .content {
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          margin-top: 70px;
          font-size: $title_sm;
        }

        img {
          @media screen and (max-width: 800px) {
            max-width: 90%;
          }
        }
      }

      @media screen and (max-width: 1150px) {
        margin-top: 50px;
      }
    }

    @media screen and (max-width: 1150px) {
      padding: 5vh 5vh;
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 800px) {
      padding: 4vw 4vw;
    }
  }

  .testimonials {
    padding: 10vh 20vw;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      color: $black;
      font-family: $avantt-bold;
      font-size: $title_md;
    }

    .content {
      margin-top: 46px;
      display: flex;

      .testimonial {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;
        box-sizing: border-box;
        padding: 10px 20px;
        border-right: 1px solid rgba(0, 0, 0, 0.362);

        &.one {
          border-left: 1px solid rgba(0, 0, 0, 0.362);
        }
        .quote {
          align-self: flex-start;
          margin-left: 20px;
        }

        p {
          margin-top: 24px;
          font-family: $avantt-regular;
        }

        @media screen and (max-width: 800px) {
          width: 100%;
          margin-bottom: 40px;
          border-right: 1px solid rgba(0, 0, 0, 0.362);
          border-left: 1px solid rgba(0, 0, 0, 0.362);
        }
      }

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }
    }

    @media screen and (max-width: 700px) {
      padding: 5vw;
    }
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
  }
}

.profile_btn {
  padding: 11px 28px;
  font-family: $avantt-regular;
  font-size: $text_md;
  line-height: 24px;
  text-align: center;
  color: $white;
  margin-top: 114px;
  background: var(--primary);
  border-radius: 30px;
  display: inline-block;
  margin-top: 30px;
}

.recommended_screenings_container {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  background: #f8f8f8;
  position: relative;
  // padding-top: 5rem;
  padding-bottom: 2rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 0rem;
  }
}
.recommended_screenings_container > div {
  flex: 1 0 100%; /* Take full width */
  background: #f8f8f8;
}
.recommended_screenings_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #6a574d;
  font-size: 3rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
  @media screen and (max-width: 600px) {
    // width: 80%;
  }
}

// .recommended_screenings {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   gap: 2rem;
//   width: 100%;
//   height: auto;
//   align-items: center;
//   justify-content: space-evenly;
//   @media screen and (max-width: 600px) {
//     flex-direction: column;
//     align-items: center;
//   }

//   .AAP {
//     max-width: 15rem;
//     height: auto;
//     @media screen and (max-width: 900px) {
//       // max-width: 30rem;
//       // height: auto;
//       flex: 1 1 calc(50% - 20px); /* Adjust the width to be less than half, accounting for gap */
//       display: flex;
//       justify-content: center; /* Center logo within the div */
//     }
//   }
//   .AACAP {
//     max-width: 15rem;
//     height: auto;
//     @media screen and (max-width: 900px) {
//       // max-width: 30rem;
//       // height: auto;
//       flex: 1 1 calc(50% - 20px); /* Adjust the width to be less than half, accounting for gap */
//       display: flex;
//       justify-content: center; /* Center logo within the div */
//     }
//   }
//   .USPTF {
//     max-width: 15rem;
//     height: auto;
//     @media screen and (max-width: 900px) {
//       // max-width: 30rem;
//       // height: auto;
//       flex: 1 1 calc(50% - 20px); /* Adjust the width to be less than half, accounting for gap */
//       display: flex;
//       justify-content: center; /* Center logo within the div */
//     }
//   }
//   .ACOG {
//     max-width: 15rem;
//     height: auto;
//     @media screen and (max-width: 900px) {
//       // max-width: 30rem;
//       // height: auto;
//       flex: 1 1 calc(50% - 20px); /* Adjust the width to be less than half, accounting for gap */
//       display: flex;
//       justify-content: center; /* Center logo within the div */
//     }
//   }
//   .CHA {
//     max-width: 400px;
//     height: auto;
//     @media screen and (max-width: 900px) {
//       max-width: 30rem;
//       height: auto;
//     }
//   }
//   @media screen and (max-width: 900px) {
//     overflow: auto;
//     //   flex-direction: column;
//     //   align-items: center;
//     //   justify-content: space-evenly;
//   }

.flex-width {
  // display: flex;
  // width: 100%;
  background: #f8f8f8;
}

//   .unlock_your_path {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//   }
// }
.recommended_screenings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-evenly;

  // Styles for individual logos to make them responsive
  .AAP,
  .USPTF {
    max-width: 18rem; // Default max width
    height: auto;
  }

  .AACAP,
  .ACOG {
    max-width: 15rem; // Default max width
    height: auto;
  }
  .AAP,
  .AACAP,
  .ACOG,
  .USPTF {
    @media screen and (max-width: 900px) {
      max-width: 100%; // Make logos responsive
      flex: 0 50%; // Adjust this for 2x2 grid
      // Ensure there's no stretching
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    .AAP,
    .AACAP,
    .USPTF,
    .ACOG {
      max-width: calc(
        45% - 5rem
      ); // Adjust the width to account for the gap, ensuring two items per row
      width: calc(
        45% - 5rem
      ); // Adjust the width to account for the gap, ensuring two items per row
      height: auto; // Maintain aspect ratio
      margin: 1rem 2rem; // Center the logos in their flex containe
    }
  }
}

.image {
  display: flex;
  width: 400px;
  height: 800px;
}
