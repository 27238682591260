.TestsList {

}

.TestsList__testList {
  list-style: none;
  padding: 16px;
  margin: 16px 0
}

.TestsList__testListItem {
  display: flex;
  flex-direction: row;
  padding: 8px 4px;
  font-weight: bold;
  margin-bottom: 8px;
}

.TestsList__testListItem > div {
  flex: 1;
  text-align: center;
}

.TestsList__testListItem > div:first-of-type {
  flex: 3;
  text-align: left;
}

.TestsList__testListItem > div:last-of-type {
  text-align: right;
  max-width: 32px;
}

.TestsList__statusIcon {
  width: 18px;
  height: 18px;
}

.TestsList__testLinkColumn {
  min-width: 150px;
}