.WellnessDayOne {
  flex: 1;
  width: 100%;
  max-width: 1024px;
  margin: 96px auto;
  padding: 16px;
  font-family: 'Avantt Regular';
}

.WellnessDayOne__title {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin-bottom: 32px;
}

.WellnessDayOne__content {
  padding-left: 16px;
}

.WellnessDayOne__tableContainer {
  margin-top: 16px;
}

.WellnessDayOne__timelineLinkContainer {
  margin-top: 32px;
  text-align: center;
}

.WellnessDayOne__timelineLink {
  color: var(--primary);
  font-weight: 600;
}
