.data-privacy-section {
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
  position: relative;
  background-color: #c2d7ff;
  margin: auto;
  overflow: hidden; // Ensure that everything inside respects the border radius
  @media screen and (max-width: 800px) {
    padding-top: 4rem;
    padding-bottom: 1.5rem;
  }

  .lock-icon-container {
    position: absolute;
    top: 20%; // Adjust as per your design to move the lock up
    left: 50%;
    transform: translateX(-50%);
    z-index: 10; // Should be above all other content

    // When hovering over the lock icon, add a blur effect to all images
    &:hover + .overflow-container .image-rows .image-container .profile-image {
      filter: blur(4px); // The value can be adjusted to the desired blur amount
    }

    .lock-icon {
      background-color: #023492;
      opacity: 0.4;
      color: #fff;
    }
    .unlock-icon {
      color: #023492;
      background-color: #fff;
    }

    .lock-icon,
    .unlock-icon {
      font-size: 2.5rem; // Size of the lock icon
      border-radius: 50%;
      padding: 3rem; // Padding around the lock
      position: relative;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.5),
        0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5),
        0 0 40px rgba(255, 255, 255, 0.5), 0 0 50px rgba(255, 255, 255, 0.5),
        0 0 60px rgba(255, 255, 255, 0.5);
    }

    .lock-icon
      + .overflow-container
      .image-rows
      .image-container
      .profile-imagre {
      filter: blur(4px); // The value can be adjusted to the desired blur amount
    }

    // Create the first ring
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      border: 2px solid white;
      z-index: -1;
    }

    &:after {
      width: 10rem; // Diameter of the first ring
      height: 10rem;
      border: 1px solid white; // Semi-transparent blue ring
      box-shadow: 0 0 15px rgba(255, 255, 255, 1); // Fuzzy effect for the first ring
    }

    // Create the second ring
    &:before {
      width: 13.5rem; // Diameter of the second ring
      height: 13.5rem;
      box-shadow: 0 0 25px rgba(110, 110, 110, 0.5); // Fuzzy effect for the second ring
      border: 1px solid white; // Semi-transparent blue ring
    }

    // Adding this will create the third ring
    .extra-ring:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 17rem; // Size of the third ring
      height: 17rem;
      transform: translate(-50%, -50%);
      border: 1px solid white; // Color of the third ring
      border-radius: 50%;
      box-shadow: 0 0 35px rgba(255, 255, 255, 0.5); // Fuzzy effect for the third ring
      z-index: -1;
    }
  }

  .overflow-container {
    width: calc(100% + 160px); // Allow for overflow by adding to the width
    margin-left: -80px; // Offset the additional width to maintain centering
    overflow: hidden; // This will hide the overflowing parts
    clip-path: inset(
      0 round 20px
    ); // This clips the content to the rounded corners

    .image-rows {
      display: flex;
      flex-direction: column;
      align-items: center;

      .image-row {
        display: flex;
        justify-content: center;
        gap: 1.5rem; // Adjust the gap between images as needed
        margin-bottom: 1rem;

        .image-container {
          width: 100px; // Adjust to match your design
          height: 100px; // Adjust to match your design
          border-radius: 25%; // Circular shape
          overflow: hidden;
          @media screen and (max-width: 800px) {
            width: 80px; // Adjust to match your design
            height: 80px; // Adjust to match your design
            border-radius: 25%; // Non-Circular shape
          }

          .profile-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            &.blurred {
              filter: blur(4px); /* Adjust blur amount as desired */
            }
            &.clear {
              filter: none;
            }
            &:hover {
              filter: blur(10px);
            }

            // Transition for a smooth blur effect
            transition: filter 0.3s ease-in-out;
          }
        }
      }

      .bottom-row {
        position: relative;
        left: 0px; // Adjust this value to align with the top row's gaps
      }
    }
  }

  h2 {
    font-size: 3rem;
    font-weight: bold;
    color: #00318f;
    margin-top: 4rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 800px) {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #00318f;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 0px;
  }
}
