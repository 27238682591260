/* PeriodTracker.css */

.PeriodTracker-container {
  font-family: "Avantt Regular" !important;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.PeriodTracker-title, .PeriodTracker-sub-title {
  color: #d32f2f;
  border-bottom: 2px solid #d32f2f;
}

.PeriodTracker-font {
  font-family: "Avantt Light" !important;
}

.PeriodTracker-input-date,
.PeriodTracker-input-number {
  margin-top: 10px;
  margin-right: 5px;
  font-family: "Avantt Regular";
}

.PeriodTracker-button {
  display: block;
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #d32f2f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.PeriodTracker-button[disabled] {
  background-color: #ddd; /* Grayed out */
  cursor: not-allowed; /* Indicate the button is not clickable */
}

.PeriodTracker-button:hover {
  background-color: #c62828;
}


/* New css */
.PeriodTracker-input-group {
  margin-top: 15px;
}

.PeriodTracker-input-date, .PeriodTracker-input-number, .PeriodTracker-input-select {
  width: 100%;
  padding: 8px 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.PeriodTracker-symptom-slider {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.PeriodTracker-symptom-slider label {
  flex: 1;
}

.PeriodTracker-symptom-slider input[type="range"] {
  flex: 2;
  margin: 0 10px;
}

.PeriodTracker-symptom-slider span {
  width: 25px;
  text-align: center;
}
