//@tailwind base;
//@tailwind components;
//@tailwind utilities;

@import "./variables";
@import './theme';

// globals

* {
  padding: 0px;
  margin: 0px;
  text-decoration: none;
}

button
a,
select {
  cursor: pointer;
}

html,
body,
#root {
  min-height: 100%;
  height: 100%;
  height: 100vh;
}

.app {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%
}

.happy-monkey {
  font-family: $happyMonkey-regular;
}

.inter-regular {
  font-family: $avantt-regular;
}

.inter-bold {
  font-family: $avantt-bold;
}

.italiana {
  font-family: $avantt-regular;
}
