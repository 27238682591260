.WellnessStoryIntro_Step1__title {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin-bottom: 32px;
}

.WellnessStoryIntro_Step1__subTitle {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  margin-bottom: 32px;
}

.WellnessStoryIntro_Step1__buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 32px;
}

.WellnessStoryIntro_Step1__content {
  padding-left: 16px;
}