@import "../styles/variables";

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--background);
}

h1 {
  font-family: $avantt-regular;
  font-size: $hero_title;
  letter-spacing: 5%;
  text-align: center;
  color: var(--primary);
  margin: 50px 50px 50px 50px;

  @media screen and (max-width: 850px) {
    font-size: $title_md;
    font-family: $avantt-bold;
  }
}

h2 {
  font-family: $avantt-regular;
  font-size: $title_lg;
  letter-spacing: 5%;
  text-align: center;
  color: var(--primary);
  margin: 50px 50px 50px 50px;
  font-weight: 100;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  display: flex;
  // justify-content: space-around;
  width: 100%;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }

  .row {
    flex-direction: row;
  }

  @media screen and (max-width: 1200px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.container_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1;
  justify-content: space-around;
  width: 100%;
  .margin20 {
    margin-bottom: 20px;
  }

  &.bottom_image_container {
    aspect-ratio: 1440/204;
    //background: blue;
    // background-image: url("../../public/homepage_bottom.png");
    background-size: cover;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.scroll-container {
  font-family: $avantt-thin-italic;
  font-size: $title_lg;
  letter-spacing: 5%;
  text-align: center;
  color: var(--primary);
  margin: 50px 50px 50px 50px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(80%);
  }
}

.scroll-text {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  // width: 100%;
  line-height: 100px;
  @media screen and (max-width: 650px) {
    font-size: $title_md;
    line-height: 60px;
  }
  // border: 1px solid black; // Just for visual reference, you can remove this
}

.scroll-content {
  display: flex;
  font-family: $avantt-thin-italic;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  white-space: nowrap;
}

.scroll-text-left .scroll-content {
  animation-name: scrollLeft;
  animation-duration: 30s; // Adjust speed as needed
  width: 600%;
  display: flex;
}

.scroll-text-right .scroll-content {
  animation-name: scrollRight;
  animation-duration: 40s; // Adjust speed as needed
  width: 300%;
  display: flex;
}
