.horizontal-filters-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  .horizontal-filters-container {
    padding: 0 1rem;
  }
}

.horizontal-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .horizontal-filters {
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  .horizontal-filters {
    width: calc(100% - 2rem);
  }
}

.horizontal-filters-item {
  flex: 1;
  flex-shrink: 0;
  max-width: 100%;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .horizontal-filters-item {
    flex: 1;
    flex-shrink: 0;
    max-width: 18%;
  }
}

.more-filters {
  min-width: fit-content;
  padding: 10px 35px 10px 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ea5f26;
  border-radius: 4px;
  outline: none;
  color: #ea5f26;
  cursor: pointer;
  font-size: 15px;
  line-height: 1.5;
  margin-left: 45px;
  margin-top: 45px;
  margin-bottom: 10px;
  justify-content: center;
}
.more-filters:focus {
  background-color: #ea5f26;
  color: #f5f5f5;

  border-color: #3f51b5;
  box-shadow: 0 0 3px rgba(63, 81, 181, 0.5);
}

.more-filters:hover {
  background-color: #ea5f26;
  color: #f5f5f5;
}

.more-filters option {
  padding: 8px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.more-filters option:last-child {
  border-bottom: none;
}

.more-filters option:checked,
.more-filters option:hover {
  background-color: #e0e0e0;
}

.more-filters option[data-divider="true"] {
  border-top: 1px solid #c0c0c0;
  padding-top: 10px;
  margin-top: 10px;
}
