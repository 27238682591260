.QuizFormInput {
  display: flex;
  justify-content: center;
  font-weight: normal;
  margin: 5% 0 32px;
}

.QuizFormInput-question {
  font-family: "Avantt Bold";
  margin-bottom: 10px;
}

.QuizFormInput-description {
  font-family: "Avantt Light";
  line-height: 1.2;
  margin-bottom: 10px;
}

/* .QuizFormInput-optionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  font-family: "Avantt Regular";
  margin-top: 8px;
  padding: 0;
  gap: 16px;
  line-height: 1.3;
  @media screen and (max-width: 768px) {
    gap: 1rem;
    flex-wrap: wrap;
  }
} */
.QuizFormInput-optionsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; /* Allow wrapping */
  justify-content: center;
  gap: 1rem;
  max-width: 600px; /* Adjust to fit two columns nicely */
  margin: 0 auto; /* Center the container */
}

.QuizFormInput-option {
  flex: 1;
  position: relative;
  min-width: auto;
  max-width: 300px;
  @media screen and (max-width: 768px) {
    min-width: 150px;
  }
}

.QuizFormInput-option.small {
  min-width: 50px;
  max-width: 50px;
}

/* .QuizFormInput-label {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  margin-right: 1rem;
  height: 10rem;
  width: 20rem;
  border: 1px solid #ff3ebc;
  border-radius: 30%;
  background-color: #ff3ebc;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    font-size: 1.5rem;
    padding: 0.75rem 1.5rem;
    height: 8rem;
    width: 70%;
  }
} */
.QuizFormInput-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* font-family: "Inter Bold"; */
  font-family: "Happy Monkey Regular";
  font-size: 1.5rem; /* Adjust font size */
  padding: 1.25rem 1.5rem;
  width: 100%; /* Make sure the buttons take up full space */
  border: 1px solid #ff3ebc;
  border-radius: 30px; /* More rounded for the button effect */
  background-color: #ff3ebc;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
  margin-bottom: 8px; /* Adjust spacing between buttons */
}

.QuizFormInput-radio {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.QuizFormInput-button {
  display: none;
}

.QuizFormInput-hidden-radio {
  opacity: 0;
  /* visibility: hidden; */
}

.QuizFormInput-div {
  position: relative;
  @media screen and (max-width: 768px) {
    /* width: 40%; */
    flex-wrap: wrap;
  }
}

.QuizFormInput-radio:checked + .QuizFormInput-label {
  background: var(--accent);
  color: var(--primary);
}
