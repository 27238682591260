.QuizForm {
  background-color: white;
  color: var(--primary);
  font-family: "Avantt Regular";
  font-size: 46px;
  text-align: center;
  margin-bottom: 5%;
}

.QuizForm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  font-family: "Inter Bold";
  font-size: 2.5rem;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

.QuizForm-question {
  color: black;
  /* font-family: "Inter Bold"; */
  font-family: "Happy Monkey Regular";
  font-size: 2.5rem;
  /* max-width: 90%; */
  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

.QuizForm-description {
  background-color: var(--primary);
  color: var(--background);
  font-family: "Avantt Regular";
  font-size: 30px;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

.QuizForm-button-container {
  display: flex;
  justify-content: center;
  gap: 5%;
}
