@import "./variables";

.select-container-sort {
  position: relative;
  display: inline-block;
  font-family: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
}

.custom-select-sort {
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
}
.custom-select-sort:hover {
  color: #ea5f26 !important;
}

.custom-select-sort .caret {
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  // left: 0;
  right: 10%;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 20rem;
  font-family: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  right: 30px;
}

.dropdown-item:hover {
  background-color: #f6b59c;
  color: rgb(91, 91, 91) !important;
  font-family: $avantt-bold !important;
}
