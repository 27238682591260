

.WellnessStoryTestHardStepTwo__title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 32px;
}

.WellnessStoryTestHardStepTwo__subTitle {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 32px;
}

.WellnessStoryTestHardStepTwo__contentContainer {
  padding-left: 16px;
}

.WellnessStoryTestHardStepTwo--marginBottom {
  margin-bottom: 16px;
}

.WellnessStoryTestHardStepTwo__fieldContainer {
  width: 400px;
  max-width: 100%;
}

.WellnessStoryTestHardStepTwo__buttonContainer {
  text-align: right;
}