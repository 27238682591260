.screening-section {
  position: relative;
  background: #f2b540;
  padding-top: 1rem;
  padding-bottom: 5rem;
  overflow: hidden;

  .screening-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 26rem;
    position: relative;
    mask-image: linear-gradient(
      to bottom,
      transparent,
      black 10%,
      black 90%,
      transparent
    ); // This creates the fading edges

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 10%; // This will control the size of the fuzzy effect
      z-index: 1;
      background: inherit; // This takes the background color of the .screening-section
    }

    &:before {
      top: 0;
      background: linear-gradient(to bottom, #f2b540, transparent);
    }

    &:after {
      bottom: 0;
      background: linear-gradient(to top, #f2b540, transparent);
    }
  }

  .screening-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    list-style: none;
    gap: 1rem;
    margin: 0;
    padding: 0;
    animation: scroll 120s linear infinite; // Adjust time for scrolling speed

    li {
      flex-shrink: 0;
      white-space: nowrap;
      background: #ea5f26;
      border-radius: 15px; // Adjust as necessary
      padding: 10px 15px; // Adjust as necessary for your layout
      color: #f2b540;
      font-size: 2rem;
      text-align: center;
      font-weight: bold; // Bold text
      margin: 5px 0; // Adjust for space between items
      // Allow the background to fit the content width
      display: inline-block; // Make the list item inline-block to fit content width
      width: fit-content; // Width auto for natural content width

      &:before {
        content: "✔";
        display: inline-block;
        margin-right: 20px;
        margin-left: 10px;
        color: #f2b540;
        font-weight: 200;
      }
    }

    // Keyframes for the scrolling effect
    @keyframes scroll {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-50%);
      }
    }
  }

  .screening-title {
    font-size: 3rem; // Adjust as necessary for your layout
    font-weight: bold;
    color: #c84813; // Text color
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.4rem !important; // Adjust as necessary for your layout
    line-height: 2rem;
    color: #c84813; // Text color
    margin-top: 10px; // Adjust as necessary for your layout
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
    margin-top: 0px;
  }
}
