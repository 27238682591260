/* General container styles */
.QuizFormInput {
  font-weight: normal;
  margin: 5% 0 32px;
  display: flex;
  justify-content: center;
}

.QuizFormInput-optionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  font-family: "Avantt Regular";
  margin-top: 8px;
  padding: 0;
  gap: 16px;
  line-height: 1.3;
  @media screen and (max-width: 768px) {
    gap: 1rem;
    flex-wrap: wrap;
  }
}

.QuizFormInput-radio {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.quiz-container {
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
  @media screen and (max-width: 768px) {
    gap: 1rem;
    flex-wrap: wrap;
  }
}

/* Question styles */
.quiz-question {
  font-size: 1.5rem;
  font-family: "Inter Bold";
  font-weight: bold;
  color: red;
  text-align: center;
  margin-bottom: 2rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

/* Options container */
.quiz-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

/* Individual option styles */
.quiz-option {
  background-color: #ff3ebc;
  color: #fff;
  padding: 0.8rem 1.2rem;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 100px;
}

.quiz-option:hover {
  background-color: #d933a3;
}

.quiz-label {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  margin-right: 1rem;
  height: 10rem;
  width: 15rem;
  border-radius: 10%;
  color: var(--primary);
  cursor: pointer;
  transition: background 0.3s;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    font-size: 1.5rem;
    padding: 0.75rem 1.5rem;
    height: 8rem;
    width: 70%;
  }
}

.quiz-radio {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
