.PersonalityTestForm-marginTopBottom {
  margin: 32px 0;
}

.mobile-false {
  margin: 32px 0;
}

.mobile-true {
  display: none;
}

.PersonalityTestForm-prompt {
  font-weight: bold;
}

.PersonalityTestForm-content {
  padding: 0 32px;
}

.PersonalityTestForm-formHeader {
  font-weight: bold;
}

.PersonalityTestForm-buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}
.WellnessTest-buttonContainer{
  margin-inline: auto;
  padding-top: 30px;
}
