.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  /* padding: 2rem 0rem !important; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: white;
  position: relative; /* Set card as the reference point for absolute positioning of the modal */
  font-family: "Avantt Regular", sans-serif;
}
.card-padding {
  padding: 2rem 3rem !important;
}

.card-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.card-logo {
  margin-right: 12px;
}

.card-logo img {
  max-width: 100px;
  height: auto;
}

.card-content {
  width: 100%;
  text-align: left;
}

.card-info p {
  margin: 8px 0;
  font-size: 0.9em;
  color: #777;
}

.link {
  /* font-family: "Open Sans", sans-serif; */
  color: var(--primary);
  font-size: 2rem;
  text-align: center;
  /* margin-left: 20%; */
  font-weight: 600;
}

.subheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: baseline;
  gap: 1rem;
  margin: 1.5rem 0;
  font-family: "Avantt Bold Light";
  font-size: 1.25rem !important;
  color: black !important;
}
.price-subheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: baseline;
}

.card-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
  padding-right: 2.5rem;
  margin-bottom: 2rem;
  width: 100%;
}

.dropdown-card-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  width: 98%;
}
.dropdown-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.card-buttons button {
  margin-left: 1rem;
  margin-right: 1rem;
}

.join-button {
  justify-content: flex-end;
}

/* Modal styles */
.modal {
  position: absolute;
  top: 100%; /* Places modal directly below the card */
  left: 0;
  width: 100%; /* Matches the card's width */
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 2rem 3rem; /* Matches padding inside the card */
  box-sizing: border-box;
  background-color: #ff8351;
  border: 6px solid white;
}

.modal .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  border: #ffe4d9 20px solid;
}

.modal-return-button {
  margin-left: 10%;
}
.action-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-content {
  border: 10px;
}

.description-details {
  /* margin-top: 1.5rem; */
  margin: 3.5rem 1rem -2rem 1rem;
  background-color: #ffb890;
  height: fit-content;
  /* padding: 2rem 1rem 2rem 1rem; */
  width: 100%;
  border-radius: 20px;
}

.description {
  font-size: 1.5rem;
  font-family: "Avantt Regular", sans-serif;
  padding: 2rem 1rem 2rem 1rem;
}

.details-dropdown {
  position: relative;
  background-color: #fee5d9;
  width: 100%;
  border-top: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-bottom: 2rem;
  border-bottom-left-radius: 10px; /* Adjust the value as needed */
  border-bottom-right-radius: 10px; /* Adjust the value as needed */
}
.details-close-button {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  font-size: 2rem;
  cursor: pointer;
  background: none;
  border: none;
}
.details-description {
  margin-top: 3rem;
  /* margin-bottom: 2rem; */
  font-family: "Avantt Regular", sans-serif;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 2rem;
}
