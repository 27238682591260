.referral-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.referral-modal-content {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  max-width: 700px;
  height: 300px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ff8351;
  border: 6px solid white;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 35px;
  cursor: pointer;
}

.modal-description {
  margin-bottom: 20px;
  text-align: center;
  margin-right: 20px;
  font-weight: 400;
  font-size: 1.75rem;
}
.return-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
  margin-left: 50px;
}

.modal-return {
  padding-left: 170px;
}
