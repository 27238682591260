@import "./variables";
@import "./theme";

.mobile_navbar {
  display: none;
  font-family: $avantt-regular;
  font-size: $text_md;
  background-color: white;

  .blueberry-logo {
    display: flex;
    height: 4rem;
    width: 4rem;
  }
  .logo-text {
    font-size: 1rem;
  }

  .navbar_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 50px;
    left: 0;
    right: 0;
    box-sizing: border-box;
    border-radius: 0;
    font-size: 1.8rem;
    background-color: white;
    border-bottom: 1px solid #e5e5e5;

    a {
      margin-left: 20px;
    }

    .hamburger {
      margin-right: 25px;
      width: 30px;
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      cursor: pointer;
      .line {
        width: 100%;
        height: 3px;
        background-color: var(--accent);
      }
    }

    .close {
      font-size: $title_md;
      cursor: pointer;
      margin-right: 33px;
    }

    .wellness-close {
      color: var(--accent);
    }
  }

  nav {
    background-color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    top: 50px;
    left: 0;

    ul {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        list-style: none;
        margin-top: 20px;
        a {
          color: $black;
          font-family: $avantt-regular;
          font-size: $text_md;
          font-weight: bold;
          display: block;
        }

        .signUp {
          padding: 13px 34px;
          background-color: $dark_green;
          color: $white;
          font-family: $avantt-bold;
          border-radius: 10px;
        }

        .login {
          font-family: $avantt-bold;
        }

        .logout {
          background-color: transparent;
          border: none;
          font-family: $avantt-bold;
          font-size: $text_md;
        }
      }
    }
  }

  @media screen and (max-width: 920px) {
    background-color: $green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    position: fixed;
    z-index: 12;
    height: 50px;
    width: 100%;
  }

  #google_translate_element {
    .skiptranslate.goog-te-gadget {
      color: green;
      font: 0px;
      line-height: 0px;
    }

    span {
      display: none;
    }

    select {
      outline: none;
      padding: 5px;
      border-radius: 100px;
      width: 100px;
      // background-color: #1b5bbc;
    }
  }

  iframe.skiptranslate {
    display: none;
  }
}

.wellness {
  background-color: var(--primary) !important;

  a,
  button {
    color: $white !important;
  }
}
