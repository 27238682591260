.four-toggle-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 20px;
  background-color: var(--primary); /* Set to your inactive background color */
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional: adds shadow for depth */
  width: 140px;

  .toggle-option {
    background: none;
    border: none;
    color: var(--light); /* Set to your inactive color */
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }

  .toggle-option.active {
    color: var(--active-color); /* Set to your active text color */
    background-color: var(--accent); /* Set to your active background color */
    transform: scale(
      1.1
    ); /* Optional: makes the active option slightly larger */
    z-index: 1; /* Ensures the active option stays above the others */
  }

  @media screen and (max-width: 680px) {
    width: 100%;
    max-width: 500px;
  }
}

.four-toggle-switch {
  display: flex;
  justify-content: space-between;
  background: #444; /* Replace with the color of the toggle background */
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px;
  width: 440px;
  @media screen and (max-width: 680px) {
    margin-top: 3rem;
  }
}
.four-toggle-button {
  flex: 1;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  background: transparent;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 16px; /* Adjust font size as needed */
}
.four-toggle-button:not(:last-child) {
  margin-right: 2px; /* Adjust the space between buttons */
}
.four-toggle-button.active {
  background: #fff; /* Replace with the color of the active button */
  color: #444; /* Replace with the color of the active button text */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Optional: raises the active button */
}
