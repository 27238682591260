.TextArea {
  margin-bottom: 8px;
  font-size: 14px;
  width: 100%;
}

.TextArea__textarea {
  resize: none;
  width: 100%;
  height: 75px;
  background: #FFFFFF;
  border: 1px solid #051F47;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
}