.new-referrals-page-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 100%;
  /* emma */
  /* overflow-x: hidden; */
}

.referral-page-content {
  width: 100%;
  max-width: 1240px;
  margin: auto;
}

.new-referrals-page-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 100%;
  /* emma */
  /* overflow-x: hidden; */
}

.new-referrals-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.platform-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .platform-container {
    flex-direction: column;
  }
}

.results-sort-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  font-size: 1.25rem;
  font-family: "Avantt Regular";
  color: grey;
  /* margin-left: 3rem; */
  /* margin-right: 8rem; */
}

.showing-results-text {
  text-align: left;
  margin-top: 1.5rem;
}

.select-container-sort {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: right; /* Ensures right alignment */
  margin-left: auto;
  /*margin-right: 5rem;*/
}

/* .horizontal-filters {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  justify-content: left;
  align-items: center;
  text-align: center;
  margin-left: 3rem;
} */
