@import "../styles/variables";

.profile {
  max-width: 100%;

  .titles {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding-top: 2vh;
    padding-bottom: 4vh;
    text-align: center;

    .title-buttons-container {
      display: flex;
      flex-direction: row;
      gap: 8px;
      justify-content: space-between;
      margin-top: 18px;
      @media screen and (max-width: 500px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .title-buttons-container > * {
      justify-content: space-around;
    }
    .title-buttons-container > *:first-of-type {
      justify-content: flex-start;
    }
    .title-buttons-container > *:nth-of-type(2) {
      justify-content: center;
      margin-right: 7rem;
      @media screen and (max-width: 500px) {
        margin-right: 0;
      }
    }
    .title-buttons-container > *:last-of-type {
      justify-content: flex-end;
    }

    .Button-primary {
      background-color: var(--primary);
      color: var(--light);
      border: none;
      position: relative;
      @media screen and (max-width: 900px) {
        padding-left: 2vw;
        padding-right: 2vw;
        max-width: 30%;
      }
    }

    .story,
    .check-in {
      .popup-message {
        display: none;
      }

      &:hover,
      &:active,
      &:focus {
        .popup-message {
          display: block;
          position: absolute;
          z-index: 10;
          left: calc(25%);
          top: calc(38%);
          background: white;
          border: 3px solid #053b47;
          border-radius: 20px;
          color: #053b47;
          padding: 20px 15px;
          min-width: 200px;
          max-width: 300px;
        }
      }

      // &:hover,
      // &:active,
      // &:focus {
      //   .popup-message {
      //     left: -10%;
      //     display: block;
      //     position: absolute;
      //     z-index: 10;
      //     width: 100%;
      //     top: calc(100% + 5px);
      //     background: white;
      //     border: 3px solid #053b47;
      //     border-radius: 20px;
      //     color: #053b47;
      //     padding: 35px 21px;
      //   }
      // }
    }

    h1 {
      color: var(--primary);
      font-size: $title_lg;
      font-family: $avantt-bold;
      text-align: center;
    }

    h2 {
      text-align: center;
      display: block;
      margin-top: 30px;
      font-size: $title_md;
      font-family: $avantt-regular;
    }

    @media screen and (max-width: 900px) {
      padding-left: 2vw;
      padding-right: 2vw;
      max-width: 90%;
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: end;

    h2 {
      @media screen and (max-width: 670px) {
        margin-bottom: 20px;
      }
    }

    .share_button {
      color: $dark_green;
      font-family: $avantt-bold;
      font-size: $text_md;
      display: flex;
      align-items: center;
      border: none;
      font-weight: bold;
      background-color: transparent;

      img {
        width: 40px;
        margin-left: 10px;
      }

      @media screen and (max-width: 1280px) {
        margin-top: 40px;
      }
    }

    .edit_save {
      display: flex;
      align-items: center;

      span {
        font-family: $avantt-regular;
        font-weight: bold;
        margin-right: 40px;
        font-size: $text_md;
      }
      .save_button {
        padding: 13px 34px;
        background-color: $dark_green;
        color: $white;
        font-family: $avantt-regular;
        font-size: 16px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        border: none;
        font-weight: bold;

        span {
          margin-right: 10px;
        }
      }
    }

    @media screen and (max-width: 560px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .tests-section,
  .physical-health-container {
    background-color: white;
    border-top: 3px solid var(--accent-transparent);
    padding: 30px;

    .subtitle {
      color: grey;
    }

    .heading {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;

      h2 {
        margin: 0;
        @media screen and (max-width: 670px) {
          margin-bottom: 20px;
        }
      }

      .edit_save {
        display: flex;
        align-items: center;

        span {
          font-family: $avantt-regular;
          font-weight: bold;
          margin-right: 40px;
          font-size: $text_md;
        }
        .save_button {
          padding: 13px 34px;
          background-color: $dark_green;
          color: $white;
          font-family: $avantt-regular;
          font-size: 16px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          border: none;
          font-weight: bold;

          span {
            margin-right: 10px;
          }
        }
      }

      @media screen and (max-width: 990px) {
        flex-direction: column;
        align-items: center;
      }
    }

    h2 {
      font-size: $title_md;
      font-family: $avantt-bold;
      max-width: none;
    }

    .tests {
      margin-top: 60px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .col {
        margin-right: 44px;

        @media screen and (max-width: 1248px) {
          margin-right: 0px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        @media screen and (max-width: 992px) {
          width: 100%;
        }

        @media screen and (max-width: 670px) {
          flex-direction: column;
          align-items: center;
        }
      }

      @media screen and (max-width: 1248px) {
        align-items: flex-start;
        flex-wrap: wrap;
      }

      @media screen and (max-width: 670px) {
        flex-direction: column;
        align-items: center;
      }
    }

    @media screen and (max-width: 670px) {
      padding: 12px;
    }
  }

  .relationship {
    background-color: $dark_skin;
    padding: 30px;
    padding: 30px;

    .heading {
      display: flex;
      justify-content: space-between;

      h2 {
        @media screen and (max-width: 670px) {
          margin-bottom: 20px;
        }
      }

      .edit_save {
        display: flex;
        align-items: center;

        span {
          font-family: $avantt-regular;
          font-weight: bold;
          margin-right: 40px;
          font-size: $text_md;
        }
        .save_button {
          padding: 13px 34px;
          background-color: $dark_green;
          color: $white;
          font-family: $avantt-regular;
          font-size: 16px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          border: none;
          font-weight: bold;

          span {
            margin-right: 10px;
          }
        }
      }

      @media screen and (max-width: 990px) {
        flex-direction: column;
        align-items: center;
      }
    }

    h2 {
      font-size: $title_md;
      font-family: $avantt-bold;
      max-width: none;
    }

    .tests {
      margin-top: 60px;
      display: flex;
      align-items: center;

      .col {
        margin-right: 44px;

        @media screen and (max-width: 1248px) {
          margin-right: 0px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        @media screen and (max-width: 992px) {
          width: 100%;
        }

        @media screen and (max-width: 650px) {
          flex-direction: column;
          align-items: center;
        }
      }

      @media screen and (max-width: 1248px) {
        align-items: flex-start;
        flex-wrap: wrap;
      }

      @media screen and (max-width: 670px) {
        flex-direction: column;
        align-items: center;
      }
    }

    @media screen and (max-width: 670px) {
      padding: 12px;
    }
  }

  .career {
    background-color: $light_skin;
    padding: 30px;
    padding: 30px;

    .heading {
      display: flex;
      justify-content: space-between;

      h2 {
        @media screen and (max-width: 670px) {
          margin-bottom: 20px;
        }
      }

      .edit_save {
        display: flex;
        align-items: center;

        span {
          font-family: $avantt-regular;
          font-weight: bold;
          margin-right: 40px;
          font-size: $text_md;
        }
        .save_button {
          padding: 13px 34px;
          background-color: $dark_green;
          color: $white;
          font-family: $avantt-regular;
          font-size: 16px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          border: none;
          font-weight: bold;

          span {
            margin-right: 10px;
          }
        }
      }

      @media screen and (max-width: 990px) {
        flex-direction: column;
        align-items: center;
      }
    }

    h2 {
      font-size: $title_md;
      font-family: $inter-bold;
      max-width: none;
    }

    .tests {
      margin-top: 60px;
      display: flex;
      align-items: center;

      .col {
        margin-right: 44px;

        @media screen and (max-width: 1248px) {
          margin-right: 0px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        @media screen and (max-width: 992px) {
          width: 100%;
        }

        @media screen and (max-width: 650px) {
          flex-direction: column;
          align-items: center;
        }
      }

      @media screen and (max-width: 1248px) {
        align-items: flex-start;
        flex-wrap: wrap;
      }

      @media screen and (max-width: 670px) {
        flex-direction: column;
        align-items: center;
      }
    }

    @media screen and (max-width: 670px) {
      padding: 12px;
    }
  }

  @media screen and (max-width: 920px) {
    padding-top: calc(20px);
  }

  .join_now {
    color: white;
  }
}

.mental-health-score {
  .score-level {
    padding: 1.5rem 2rem;
    border-radius: 20px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-family: $avantt-bold;
    min-width: 10rem;

    &.critical {
      background-color: #ff4e50; // red
    }
    &.help-needed {
      background-color: #fc913a; // orange
    }
    &.stable {
      background-color: #f9d423; // yellow
    }
    &.good {
      background-color: #e2f4c7; // light green
      color: black;
    }
    &.excellent {
      background-color: #2f9395; // dark green
    }
    &.none {
      background-color: var(--accent); // red
    }
  }

  .score-value {
    font-size: 1.5rem;
    color: #fff;
    font-weight: bold;
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

