@import "../styles/variables";

.signup-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  font-family: $avantt-regular;
  margin-bottom: 5rem;
  box-sizing: border-box;

  h1 {
    font-family: $avantt-bold;
    font-size: $title_lg;
    margin-bottom: 20px;
  }

  .no-signup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10rem;
  }

  .question {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    color: rgb(108, 108, 108);
    margin-top: 20px;
    font-size: 1.5rem;
    @media screen and (max-width: 900px) {
      max-width: 90%;
      font-size: 2rem;
    }

    b {
      color: $black;
      font-size: 1.75rem;
      font-weight: 800 !important;
      @media screen and (max-width: 900px) {
        font-size: 2.5rem;
      }
    }
  }

  form {
    max-width: 60%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    // margin-left: auto;
    gap: 1rem;

    @media screen and (max-width: 920px) {
      width: 100%;
      max-width: 80%;
    }

    .names {
      display: flex;

      input {
        width: 136px;
        display: block;
      }
      @media screen and (max-width: 920px) {
        flex-direction: column;
      }
    }

    // input {
    //   margin-bottom: 28px;
    //   height: 43px;
    //   border-radius: 10px;
    //   background-color: $gray;
    //   outline: none;
    //   border: none;
    //   box-sizing: border-box;
    //   padding: 10px;
    //   font-family: $avantt-regular;
    // }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    select {
      font-size: 16px;
      padding: 10px 15px;
      border: 2px solid var(--primary);
      border-radius: 12px;
      color: #333;
      margin: 1rem;
      outline: none;
      width: 20rem;
      max-width: 20rem;
      background-color: #fff;
      cursor: pointer;
      transition: background-color 0.3s ease;

      @media screen and (max-width: 800px) {
        width: 30rem;
        max-width: 30rem;
      }
    }

    .hear_about_us {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-size: $text-md;
        font-family: $avantt-bold;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      .radio-wrapper {
        display: flex;
        align-items: center;
        label {
          margin-right: 15px;
        }

        input {
          margin: 0;
        }
        // @media screen and (max-width: 900px) {
        //   max-width: 300px;
        //   width: 300px;
        // }
      }
      .radio-wrapper-secondary {
        display: flex;
        align-items: center;
        background-color: #fff;
        label {
          margin-right: 15px;
        }

        input {
          margin: 0;
        }
      }
    }
    .hear_about_us_options {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
    }
    .hear_about_us_options_detail {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .age,
    .location {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      h3 {
        font-size: $text-md;
        font-family: $avantt-bold;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: 1rem;
      }
      @media screen and (max-width: 800px) {
        flex-direction: column;
      }
    }

    .signing-up-for,
    .specify-relationship,
    .sex {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      flex-wrap: wrap;
      gap: 2rem;
      h3 {
        font-size: $text-md;
        font-family: $avantt-bold;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: 2rem;
      }

      .signing-up-for_options {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        @media screen and (max-width: 800px) {
          gap: 2rem;
        }
      }

      .relationship_options {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        gap: 2rem;
        row-gap: 4rem;
      }

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }
    }

    .sex_options {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
    }

    span {
      font-family: $avantt-regular;

      a {
        color: $black;
      }
    }

    .pregnant_details {
      font-size: $text-sm;
      margin-bottom: 1rem;
    }

    .forgot {
      margin-top: 20px;
      cursor: pointer;
      color: $blue;
    }

    .reset_title {
      margin-bottom: 20px;
      font-size: $text_md;
      font-family: $avantt-bold;
    }
  }

  .radio-wrapper,
  .radio-wrapper-secondary {
    position: relative;
    margin: 10px 0;
  }

  .radio-wrapper,
  .radio-wrapper-secondary input[type="radio"] {
    display: none;
  }

  .radio-wrapper label {
    padding: 13px 30px;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
    font-family: $avantt-regular;
    font-size: 1.3rem;
  }
  .radio-wrapper-secondary label {
    padding: 13px 20px;
    background-color: white;
    border: 1px solid var(--primary);
    color: var(--primary);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
    font-family: $avantt-regular;
    font-size: 1.3rem;
  }

  .radio-wrapper input[type="radio"]:checked + label,
  .radio-wrapper-secondary input[type="radio"]:checked + label {
    background-color: var(--primary);
    color: white;
  }

  .radio-wrapper label:before {
    content: "";
    width: 14px;
    height: 14px;
    border: 2px solid var(--primary);
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }

  // .radio-wrapper-secondary label:before {
  // }

  .radio-wrapper input[type="radio"]:checked + label:before,
  .radio-wrapper-secondary input[type="radio"]:checked + label:before {
    display: block;
  }

  .radio-wrapper label:after {
    content: "";
    width: 8px;
    height: 8px;
    background-color: var(--primary);
    border-radius: 50%;
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }
  // .radio-wrapper-secondary label:after {
  // }

  .radio-wrapper input[type="radio"]:checked + label:after,
  .radio-wrapper-secondary input[type="radio"]:checked + label:after {
    display: block;
  }

  .confirm_message {
    font-family: $avantt-bold;
    font-size: $text_md;
  }

  .go_back {
    margin-top: 28px;
    padding: 16px 16px;
    background-color: $dark_blue;
    color: $white;
    font-family: $avantt-bold;
    border-radius: 10px;
    max-width: 118px;
  }

  @media screen and (max-width: 920px) {
    padding-top: 6vh;
    display: flex;
  }
}

.error {
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: $text_md;
  font-family: $avantt-bold;
  color: red;
  font-weight: 700;
}

.custom-select-container {
  position: relative;
}

.custom-select {
  position: relative;
  min-width: 243.97px;
  height: 46.03px;
  padding: 12px 35px 12px 15px; /* Adjusted for space for the arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: $avantt-regular;

  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 19.95px 9.21px;

  /* Filter */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12.2754px 21.482px 12.2754px 13.0427px;
  gap: 62.91px;

  width: 243.97px;
  height: 46.03px;

  background: #f9f9f9;
  border: 0.767216px solid #6a574d;
  box-shadow: 0px 0.767216px 1.53443px rgba(16, 24, 40, 0.05);
  border-radius: 3.83608px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.age-select {
  position: relative;
  width: 243.97px;
  height: 46.03px;
  padding: 15px 12px 12px 15px;
}
/* signUpWellness.scss */

.select-container {
  position: relative;
  width: 243.97px;
  height: 46.03px;
  padding: 15px 12px 12px 15px;
}

.arrow-container {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust if necessary */
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  transform: translateY(-50%); /* Vertically center the arrow */
  pointer-events: none; /* Ensures the arrow doesn't block clicks */
}

.arrow {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE4IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguOTI4MDUgNy42MTkxNEwwLjI5MDQ4NSAwLjcxNDIwTDcuNTY1NiAwLjcxNDU2TDguOTI4MDUgNy42MTkxNFoiIGZpbGw9IiM2QTU3NEQiLz48L3N2Zz4=");
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  transform: rotate(140deg);
}

/* signUpWellness.scss */

.custom-select::after,
.custom-select-wide::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust if necessary */
  width: 243.97px;
  height: 46.03px;
  background: #6a574d;
  transform: rotate(120deg) translateY(-50%);
  pointer-events: none; /* Ensures the arrow doesn't block clicks */
}

.custom-select-container::after {
  // content: "\25BC";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-select:focus {
  border-color: #3f51b5; /* A shade of blue from Material UI palette */
  box-shadow: 0 0 3px rgba(63, 81, 181, 0.5);
}

.custom-select:hover {
  background-color: #eee;
}

.custom-select option {
  padding: 8px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  font-family: $avantt-light;
}

.custom-select option:last-child {
  border-bottom: none;
}

.custom-select option:checked,
.custom-select option:hover {
  background-color: #e0e0e0;
}

.custom-select option[data-divider="true"] {
  border-top: 1px solid #c0c0c0;
  padding-top: 10px;
  margin-top: 10px;
}

.signup {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.span {
  font-size: 15px;
  font-family: $avantt-regular;
}
