.ProgressionForm {
  background-color: var(--primary);
  color: var(--background);
}

.ProgressionForm {
  background-color: var(--primary);
  color: var(--background);
  font-family: "Avantt Regular";
  font-size: 46px;
  text-align: center;
  margin-bottom: 5%;
}

.ProgressionForm-question {
  background-color: var(--primary);
  color: var(--background);
  font-family: "Avantt Regular";
  font-size: 46px;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
}

.ProgressionForm-description {
  background-color: var(--primary);
  color: var(--background);
  font-family: "Avantt Regular";
  font-size: 30px;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

.ProgressionForm-button-container {
  display: flex;
  justify-content: center;
  gap: 5%;
}
