.WellnessTest {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: 80%;
  margin: auto;
  margin-top: 40px;
  padding: 16px 0px 16px;
  font-family: "Avantt Regular";

  @media screen and (max-width: 680px) {
    max-width: 100%;
  }

  .description {
    padding-bottom: 10px;
    padding-left: 25px;

    p {
      font-weight: bold;
    }
  }

  .WellnessTest-Chat {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    // max-width: 80%;
    margin: auto;
    margin-top: 40px;
    padding: 16px 0px 16px;
    font-family: "Avantt Regular";

    .description {
      padding-bottom: 10px;
      padding-left: 25px;

      p {
        font-weight: bold;
      }
    }
  }

  .WellnessTest-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    vertical-align: top;
    position: relative;
    margin: 0px 50px;
    padding-top: 10px;
    @media screen and (max-width: 980px) {
      flex-direction: column;
    }

    .WellnessTest-testDetails {
      color: #423434;
      font-size: 14px !important;
    }
  }
}
