.PageNotFound {
  width: 100%;
  max-width: 1024px;
  font-family: 'Avantt Regular';
  margin: auto
}

.PageNotFound__title {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 16px
}

.PageNotFound__desc1 {
  text-align: center;
  margin-bottom: 16px;
  font-size: 24px
}

.PageNotFound__desc2 {
  text-align: center;
  margin-bottom: 8px
}

.PageNotFound__linksContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px
}

.PageNotFound__link {
  color: var(--primary);
  cursor: pointer;
  font-weight: bold
}
