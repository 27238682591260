.partners-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 40px 20px; */
}

.partners-section-title {
  font-size: 2rem; /* Set font size for both title and subtext */
  font-weight: 400; /* Use normal font weight for both */
  color: #1a2e4c; /* Unified dark blue color */
  display: inline; /* Make title inline so it flows with subtext */
}

.partners-section-subtext {
  font-size: 2rem; /* Match title font size */
  font-weight: 400; /* Match title font weight */
  color: #e74c3c; /* Red color for contrast */
  display: inline; /* Make subtext inline to align next to the title */
}

.partners-container {
  margin-bottom: 30px; /* Add spacing between text and grid */
}

.partners-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;

  @media (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.partner-logo-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1rem solid #ccc;
  padding: 10px;
}

.partner-logo {
  max-width: 80%;
  max-height: 80%;
}
