.Welcome {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  margin: 96px auto;
  font-family: "Avantt Regular";
  font-size: 40px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}
.Welcome-title {
  font-family: "Avantt Bold";
}
.Welcome-content {
  padding: 0 10vw;
  @media screen and (max-width: 900px) {
    padding: 0 5vw;
  }
}
.Welcome-title,
.Welcome-spaceBottom {
  margin-bottom: 16px;
}

.Welcome-testList {
  list-style: none;
  padding: 16px;
  margin: 16px 0;
  max-width: 100%;
}

.Welcome-testListItem {
  display: flex;
  flex-direction: row;
  padding: 8px 4px;
  font-weight: bold;
  margin-bottom: 8px;
}

.Welcome-testListItem > div {
  flex: 1;
  text-align: center;
}

.Welcome-testListItem > div:first-of-type {
  flex: 2;
  text-align: left;
  max-width: 40%;
}

.Welcome-testListItem > div:last-of-type {
  text-align: right;
  max-width: 32px;
}

.Welcome-statusIcon {
  width: 18px;
  height: 18px;
}

.Welcome-buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 400px;
}

.Welcome div.popup-message
{
  position: absolute;
  top: calc(0% - 65px);
  left: 0%;
  background: white;
  border: 3px solid #053b47;
  border-radius: 20px;
  color: #053b47;
  padding: 10px 10px;
  margin-inline: auto;
  width: 100%;
  max-width: 400px;
  text-align: center;
}
