.StoryExperience {
  flex: 1;
  width: 100%;
  max-width: 1024px;
  margin: 96px auto;
  padding: 16px;
  font-family: 'Avantt Regular';
  box-sizing: border-box;
}

.StoryExperience__buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
