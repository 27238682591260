.RadioInput {
  font-weight: normal;
  margin: 16px 0 32px;
}

.RadioInput-question {
  font-family: "Avantt Bold";
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.RadioInput-description {
  font-family: "Avantt Light";
  line-height: 1.2;
  margin-bottom: 10px;
}

.RadioInput-optionsContainer {
  display: flex;
  flex-direction: row;
  font-family: "Avantt Regular";
  margin-top: 8px;
  padding: 0;
  gap: 16px;
  line-height: 1.3;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.RadioInput-option {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center; /* This will vertically align the custom radio and its label */
  min-width: auto;
  max-width: 300px;
  @media screen and (max-width: 768px) {
    min-width: 150px;
  }
}

.RadioInput-option.small {
  min-width: 50px;
  max-width: 50px;
}

.RadioInput-label {
  flex-grow: 1;
  padding-left: 3.5rem; /* Provide enough space for the custom radio button. */
  @media screen and (max-width: 900px) {
    padding-left: 6rem;
  }
  cursor: pointer;
  position: relative; /* Relative positioning so the pseudo-elements (radio buttons) are in respect to this label. */
  display: block;
  font-size: 1.25rem;  /* Added this line to set the font size */
  line-height: 1.8;   /* Adjust the line height if necessary. Consider making this proportional to the new font size. */

}

.RadioInput-radio {
  display: none; /* Hide the default radio button. */
}

.RadioInput-radio + .RadioInput-label:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid var(--primary);
  border-radius: 50%;
  position: absolute;
  left: 15px; /* Adjusted left property so the radio button is a bit more to the right. */
  top: 50%; /* Center it vertically. */
  transform: translateY(-50%); /* This will truly center it. */
  background: white;
}

.RadioInput-radio:checked + .RadioInput-label:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid var(--primary);
  border-radius: 50%;
  background: var(--primary);
  position: absolute;
  left: 15px; /* Adjusted left property so the radio button is a bit more to the right. */
  top: 50%; /* Center it vertically. */
  transform: translateY(-50%); /* This will truly center it. */
}
