.ExperienceForm {

}

.ExperienceForm__feelingContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .ExperienceForm__feelingContainer {
    flex-direction: column;
  }
}

.ExperienceForm__feelingContainer > * {
  flex: 1;
}

.ExperienceForm__feelingContainer > *:last-of-type {
  text-align: center;
}

.ExperienceForm__fieldContainer {
  width: 400px;
  max-width: 100%;
}

.ExperienceForm .ExperienceForm__feelingsButtonContainer .Button {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 10px
}

.ExperienceForm__feelingsImageContainer {
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
}