body,
html {
    margin: 0;
    padding: 0;
}

.vert-filters {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
}

@media screen and (max-width: 768px) {
    .vert-filters {
        padding: 0 2rem;
        width: 100%;
    }
}

.all-filters {
    font-size: 2em;
    color: grey;
    margin-left: 5%;
    margin-top: 5%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.filters-list {
    display: flex;
    flex-direction: column;
    align-items: right;
}

/*
Selects all direct children of the filters-list container 
  and applies width: 100% to them, ensuring that each filter
  component stretches to the full width of the container 
*/
/* .filters-list > * {
  width: 100%;
} */

.minimize-button {
    margin-right: 0;
    margin-top: -10px;
    cursor: pointer;
}

/*
 Added styles from src/styles/NewReferrals.css to match
  the other More Filters button.
 */
.more-filters-button {
    margin-left: 5%;
    margin-top: 20px;
    width: 243.97px;
}

.more-filters-button {
    min-width: fit-content;
    /* the 50px is from the padding on either side */
    /* the 5% is from the margin left */
    /* code to dynamically calculate width without overflowing parent container */
    width: 243.97px;
    padding: 10px 35px 10px 15px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ea5f26;
    border-radius: 4px;
    outline: none;
    color: #ea5f26;
    cursor: pointer;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 10px;
    justify-content: center;
}

.more-filters-button:focus {
    background-color: #ea5f26;
    color: #f5f5f5;

    border-color: #3f51b5; /* A shade of blue from Material UI palette */
    box-shadow: 0 0 3px rgba(63, 81, 181, 0.5);
}

.more-filters-button:hover {
    background-color: #ea5f26;
    color: #f5f5f5;
}

.more-filters-button option {
    padding: 8px 15px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
}

.more-filters-button option:last-child {
    border-bottom: none;
}

.more-filters-button option:checked,
.more-filters option:hover {
    background-color: #e0e0e0;
}

.more-filters-button option[data-divider="true"] {
    border-top: 1px solid #c0c0c0;
    padding-top: 10px;
    margin-top: 10px;
}
