@import "../../styles/variables";
@import "../../styles/theme";

.edit-account-profile-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050; // Ensure it's above other content
}

.edit-account-profile-modal {
  position: relative;
  background-color: #fff;
  top: 0%;
  left: 0%;
  padding: 20px;
  border-radius: 10px;
  max-width: 70%;
  width: 90%; // Adjust width as necessary, ensuring some margin from viewport edges
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 2; // Ensure modal content is above backdrop
  overflow-y: auto; // Allow vertical scrolling within the modal
  max-height: 80vh; // Set a max-height to ensure modal does not extend beyond viewport height
  margin: 20px; // Ensures there's space around the modal to not touch the viewport edges

  // Scrollbar styling (optional, for aesthetics)
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  h2 {
    font-family: $avantt-bold;
    font-size: $title_lg;
    margin-bottom: 20px;
  }

  .close-btn {
    position: absolute;
    right: 4rem;
    top: 4rem;
    background: none;
    border: none;
    font-size: 4rem;
    cursor: pointer;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem; // Ensure consistent spacing between form fields
    flex: 1;
    &:not(:last-child) {
      margin-right: 10px; // Adjust the right margin for spacing
    }

    label {
      font-size: $text-md;
      font-family: $avantt-bold;
      margin-bottom: 10px;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    select {
      font-size: 16px;
      padding: 10px 15px;
      border: 2px solid var(--primary);
      border-radius: 12px;
      color: #333;
      outline: none;
      background-color: #fff;
      min-width: 10rem;
      max-width: 20rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:focus {
        //TODO border-color: darken(var(--primary), 10%);
        border-color: var(--primary);
      }
    }
  }

  .input-group-row {
    display: flex;
    justify-content: space-between;

    .input-group {
      flex: 1;

      &:not(:last-child) {
        margin-right: 20px; // Add some space between grouped inputs
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 3rem;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
}
