.Quiz {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: auto;
  align-items: center;
  background-color: white;
  color: var(--background);
  flex-direction: column;
  padding-top: 2%;
  padding-bottom: 4rem;
  @media screen and (max-width: 900px) {
    height: auto;
  }
}

/* General container styles */
.quiz-container {
  display: flex;
  flex-direction: column;
  font-family: "Avantt Regular";
  align-items: center;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
  font-family: "Avantt Regular";
  @media screen and (max-width: 768px) {
    gap: 1rem;
    flex-wrap: wrap;
  }
}

.quiz-formHeader {
  text-align: center;
  font-size: 1.5rem;
  color: #9100c4;
}
.Quiz-header {
  display: flex;
  flex-direction: row;
  color: #fff;
  margin-bottom: 1rem;
  font-family: "Avantt Regular";
  align-items: flex-end;
}
.Quiz-title {
  font-family: "Happy Monkey Regular";
  background-color: #9100c4;
  padding: 2rem 8rem;
  border-radius: 20px;
  font-size: 3rem;
  width: 60%;
  font-weight: bold;
  margin-left: 7rem;
}

/* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  vertical-align: top;
  position: relative;
  margin-top: 30px;
  padding-top: 10px;
  background-color: var(--primary);
  color: var(--background);
  @media screen and (max-width: 980px) {
    flex-direction: column;
  } */

/* Question styles */
.quiz-question {
  font-family: "Inter Bold";
  font-size: 1.5rem;
  font-weight: bold;
  color: blue;
  text-align: center;
  margin-bottom: 2rem;
}

/* Options container */
.quiz-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

/* Individual option styles */
.quiz-option {
  background-color: #ff3ebc;
  color: #fff;
  padding: 0.8rem 1.2rem;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 100px;
}

.quiz-option:hover {
  background-color: #d933a3;
}

.quiz-label {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  margin-right: 1rem;
  height: 10rem;
  width: 15rem;
  border-radius: 10%;
  color: var(--primary);
  cursor: pointer;
  transition: background 0.3s;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    font-size: 1.5rem;
    padding: 0.75rem 1.5rem;
    height: 8rem;
    width: 70%;
  }
}

.quiz-radio {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.Quiz-testDetails {
  /* color: var(--primary); */
  color: black;
  font-size: 1.2rem !important;
}
