@import "../../styles/variables";

.PhysicalCheckIn {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.bmi-calculator-widget {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--primary); // Use your primary color or any preferred border color
  border-radius: 10px;
  padding: 20px;
  max-width: 90%;
  margin: auto;
  background-color: #f9f9f9; // Light background color for the widget

  .bmi-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 15px;
    // color: black;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    min-height: 32px;
    max-width: 30rem;
    margin-bottom: 2rem;

    .bmi-score-description {
      font-size: 1.2rem;
      text-align: left;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &.color-default {
      background-color: #f0ebe6;
    }
    &.color-good {
      background-color: #b7e4c7;
    }
    &.color-mild {
      background-color: #faf4c0;
    }
    &.color-moderate {
      background-color: #fcb373;
    }
    &.color-severe {
      background-color: #fad4d4;
    }
    &.color-youth {
      background: linear-gradient(
        90deg,
        hsl(0, 100%, 85%),
        hsl(39, 100%, 85%),
        hsl(60, 100%, 85%),
        hsl(120, 100%, 85%),
        hsl(240, 100%, 85%),
        hsl(275, 100%, 80%),
        hsl(300, 100%, 85%)
      );
      background-size: 200% 200%;
      animation: rainbow 10s ease infinite;
    }
    .color-good-change {
      color: green !important;
    }
    .color-bad-change {
      color: red !important;
    }
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-family: $avantt-regular;

  label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-family: $avantt-regular;
  }

  input {
    padding: 10px;
    border: 2px solid var(--primary);
    border-radius: 4px;
  }
}

input[type="text"],
input[type="number"],
select {
  font-size: 16px;
  padding: 10px 15px;
  border: 2px solid var(--primary);
  border-radius: 12px;
  color: #333;
  margin: 1rem;
  outline: none;
  width: 8rem;
  max-width: 14rem;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

label {
  font-family: $avantt-regular;
  font-size: 1.5rem;
}

.radio-wrapper {
  position: relative;
  margin: 10px 0;
  display: flex;
  align-items: center;
  label {
    margin-right: 15px;
  }

  input {
    margin: 0;
  }
}
.radio-wrapper input[type="radio"] {
  display: none;
}
.radio-wrapper label {
  padding: 13px 30px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  font-family: $avantt-regular;
  font-size: 1.3rem;
  width: 10rem;
}
.radio-wrapper input[type="radio"]:checked + label {
  background-color: var(--primary);
  color: white;
}
.radio-wrapper label:before {
  content: "";
  width: 14px;
  height: 14px;
  border: 2px solid var(--primary);
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.radio-wrapper input[type="radio"]:checked + label:before {
  display: block;
}
.radio-wrapper label:after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--primary);
  border-radius: 50%;
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.radio-wrapper input[type="radio"]:checked + label:after {
  display: block;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.HeartRateGraph {
  display: flex;
  width: 40rem;
  height: 40rem;
  // margin-bottom: 30rem;
  border: 2px solid var(--primary); // Use your primary color or any preferred border color
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  background-color: #f9f9f9; // Light background color for the widget
}

.HeartRate {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  margin-top: 3rem;
  align-items: baseline;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
