.quad-section {
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure the container takes the full width
  padding-top: 2rem;
  padding-bottom: 2rem;
  // background-color: #f0ebe6;
  // background-color: lightslategray;

  .row {
    gap: 4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
    }
  }

  // .row {
  //   display: flex;
  //   width: 100%; // Ensure each row spans the full container width
  //   padding: 0; // Remove any padding between rows
  //   gap: 0 !important;
  //   margin: 0 !important;

  //   .home-page-section {
  //     flex: 1;
  //     background: #f0f0f0; // Light gray background for collapsed state
  //     padding: 0; // No padding initially
  //     cursor: pointer;
  //     overflow: hidden; // Hide overflow for smooth transitions
  //     transition: all 0.4s ease; // Smooth transition for all changes
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     position: relative;
  //     margin: 0; // Ensure no margin
  //     border: none; // Ensure there's no border
  //     box-shadow: none; // Remove any default box-shadow

  //     &.collapsed {
  //       max-height: 3rem; // Set max-height for the collapsed state
  //       height: 3rem; // Set max-height for the collapsed state
  //       min-height: 3rems;
  //       padding: 0; // Ensure no padding when collapsed
  //       flex: 1; // Make sure the section doesn't shrink or grow unexpectedly
  //       color: black;
  //       text-align: center;
  //       border-radius: none !important;

  //       .privacy,
  //       .screening,
  //       .chat,
  //       .insights {
  //         width: 100%; // Ensure background spans the full width
  //         padding-left: 1rem; // Add padding to align text within the line
  //         height: 100%; // Ensure full height of the collapsed section
  //         display: flex;
  //         align-items: center;
  //         justify-content: flex-start; // Align text to the left
  //         font-size: 1rem;
  //         font-weight: bold;
  //         color: #333;
  //         border-radius: none !important;
  //       }

  //       .privacy {
  //         background-color: #c3d7fd;
  //       }
  //       .screening {
  //         background-color: #eeb550;
  //       }
  //       .chat {
  //         background-color: #e46433;
  //       }
  //       .insights {
  //         background-color: #b18beb;
  //       }

  //       .section-label {
  //         display: block;
  //         font-size: 1rem;
  //         font-weight: bold;
  //         color: #333;
  //         width: 100%; // Ensure the label spans the full width
  //         padding-left: 1rem; // Add padding to align text within the line
  //         text-align: left; // Align text to the left
  //       }
  //     }

  //     &.expanded {
  //       flex: 1; // Allow it to expand and take up available space
  //       max-height: 600px; // Adjust this height based on your content
  //       padding: 2rem; // Add padding when expanded
  //       background-color: #ffffff;
  //       box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  //       transition: max-height 0.4s ease, padding 0.4s ease;
  //       height: auto; // Allow it to grow naturally with content

  //       @media screen and (max-width: 800px) {
  //         max-height: 100%; // Allow full height on mobile
  //       }

  //       .section-label {
  //         display: none; // Hide the label when expanded
  //       }
  //     }
  //   }
  // }
}
