.WellnessDashboardTests {
  padding: 16px 0;
  font-family: "Avantt Regular";
}

.WellnessDashboardTests-container {
  max-width: 100%;
  margin: auto;
}

.WellnessDashboardTests-categoryContainer {
  border-bottom: thin solid #ccc;
  padding: 16px 8px;
}

.WellnessDashboardTests-categoryContainer:nth-of-type(2) {
  // background-color: #efefef;
  // background-color: var(--accent-transparent);
}

.WellnessDashboardTests-categoryContainer:last-of-type {
  border-bottom: none;
}

.WellnessDashboardTests-categoryTitle {
  font-family: "Avantt Bold";
  font-size: 1.2rem;
}

.WellnessDashboardTests-categoryTestsContainer {
  padding-left: 16px;
  position: relative;
  @media screen and (max-width: 900) {
    padding-left: 0px;
  }
}

.WellnessDashboardTests-categoryTest {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;
}

.WellnessDashboardTests-categoryTest > * {
  flex: 1;
  text-align: center;
}

.WellnessDashboardTests-categoryTest > *:first-of-type {
  flex: 2;
  text-align: left;
}

.WellnessDashboardTests-categoryTest > *:last-of-type {
  text-align: right;
}

.WellnessDashboardTests-testDate {
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.WellnessDashboardTests-testLink {
  color: var(--accent);
  font-weight: 600;
}
.WellnessDashboardTests-testLink-disabled {
  color: #8d8d8d;
  font-weight: 600;
  .pop-up {
    display: none;
  }

  &:hover,
  &:active,
  &:focus {
    .pop-up {
      display: block;
      position: absolute;
      z-index: 10;
      font-size: 12px;
      top: calc(0% - 25px);
      background: white;
      border: 1px solid #053b47;
      border-radius: 2px;
      color: #053b47;
      padding: 3px 3px;
    }
  }
}

.WellnessDashboardTests-showMore,
.WellnessDashboardTests-showLess {
  margin-top: 0.5rem;
  cursor: pointer;
  color: var(--primary);
  text-decoration: underline;
}

@keyframes rainbow {
  0%,
  100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}

.status {
  // display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 0.25rem 0.25rem;
  border-radius: 15px;
  color: black;
  font-weight: bold;
  text-align: center;
  // cursor: pointer;
  min-height: 32px;
  // background-color: #f0ebe6;

  &.color-default {
    background-color: #f0ebe6;
  }
  &.color-good {
    background-color: #b7e4c7;
  }
  &.color-mild {
    background-color: #faf4c0;
  }
  &.color-moderate {
    background-color: #fae1c0;
  }
  &.color-severe {
    background-color: #fad4d4;
  }
  &.color-youth {
    background: linear-gradient(
      90deg,
      hsl(0, 100%, 85%),
      hsl(39, 100%, 85%),
      hsl(60, 100%, 85%),
      hsl(120, 100%, 85%),
      hsl(240, 100%, 85%),
      hsl(275, 100%, 80%),
      hsl(300, 100%, 85%)
    );
    background-size: 200% 200%;
    animation: rainbow 10s ease infinite;
  }
}

.row {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
