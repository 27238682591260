@import "../styles/variables";
@import "../styles/theme";

.stats-section {
  padding: 6rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  background: #f8f8f8;
  text-align: center;
  columns: #6a574d;

  .stats-header {
    margin-bottom: 5rem;

    h1 {
      color: #6a574d;
      @media screen and (max-width: 600px) {
        font-size: 4rem;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .redefine {
      font-family: $avantt-light;
      font-size: $title-lg;
      @media screen and (max-width: 600px) {
        font-family: $avantt-regular;
        font-size: $title-md;
      }
    }

    p {
      // color: #666;
      font-size: 1rem;
    }
  }

  .stats-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-bottom: 3rem;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 3rem;
    }

    .stat {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      text-align: left;
      background: white;
      padding-left: 3rem;
      padding-right: 3rem;
      padding-bottom: 1rem;
      margin-left: 2rem;
      margin-right: 2rem;
      border-radius: 35px;
      width: 30%;
      max-width: 400px;
      height: 320px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 900px) {
        height: 230px;
      }
      @media screen and (max-width: 600px) {
        max-width: 300px;
        width: 80%;
        height: 190px;
      }

      h3 {
        // color: var(--primary); // Define this color variable in your root styles
        position: absolute;
        top: 9rem;
        color: #6a574d;
        font-size: 5rem;
        font-family: "Avantt Regular";
        margin-bottom: 1rem;
        @media screen and (max-width: 900px) {
          top: 7rem;
        }
        @media screen and (max-width: 600px) {
          top: 4rem;
        }
      }

      p {
        // color: #333;
        position: absolute;
        top: 15rem;
        font-size: 2rem;
        margin-bottom: 0.5rem;
        font-family: "Avantt Light";
        color: #6a574d;
        margin-bottom: 3rem;
        margin-right: 3rem;
        @media screen and (max-width: 900px) {
          top: 14rem;
        }
        @media screen and (max-width: 600px) {
          top: 12rem;
        }
      }

      small {
        position: absolute;
        top: 20rem;
        font-size: 1.5rem;
        font-family: "Avantt Light";
        color: #6a574d;
        margin-right: 2rem;

        @media screen and (max-width: 900px) {
          top: 21rem;
        }
        @media screen and (max-width: 600px) {
          top: 18rem;
        }
      }
    }
  }
}
