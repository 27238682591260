/* Form.css */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  max-width: 900px;
}

.question-container {
  background-color: white; /* Default white background */
  padding: 2rem;
  border-radius: 25px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.question-container:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
}

.question-label {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
  text-align: center;
}

.option-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.option {
  background-color: #ff69b4; /* Hot pink */
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 25px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0.5rem;
}

.option:hover {
  background-color: #ff1493; /* Slightly darker pink on hover */
  transform: translateY(-2px);
}

.option-input {
  display: none; /* Hide the radio inputs */
}

.option-selected {
  background-color: #800080; /* Dark purple for selected state */
}

.quiz-title {
  background-color: #800080; /* Dark purple */
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  display: inline-block;
}

.scale-two-prompt {
  font-size: 1.1rem;
  color: #555;
  margin: 1rem 0;
  text-align: center;
}

.answer-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

@media (max-width: 768px) {
  .question-container {
    padding: 1.5rem;
    margin-bottom: 1rem;
  }

  .option {
    padding: 0.5rem 1.25rem;
  }

  .question-label {
    font-size: 1.2rem;
  }
}
