@font-face {
  font-family: "Happy Monkey Regular";
  src: url("../fonts/HappyMonkey.ttf") format("truetype"),
    url("../fonts/HappyMonkey.woff2") format("woff2"),
    url("../fonts/HappyMonkey.eot?#iefix") format("embedded-opentype"),
    url("../fonts/HappyMonkey.woff") format("woff"),
    url("../fonts/HappyMonkey.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avantt Light";
  src: url("../fonts/Avantt_Trial/AvanttTRIAL-Light.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avantt Thin Italic";
  src: url("../fonts/Avantt_Trial/AvanttTRIAL-ThinItalic.otf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  // font-family: "Avantt Regular";
  // src: url("../fonts/Inter-Regular.ttf") format("truetype");
  font-family: "Avantt Regular";
  src: url("../fonts/Avantt_Trial/AvanttTRIAL-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avantt Bold";
  src: url("../fonts/Avantt_Trial/AvanttTRIAL-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Avantt Bold Light";
  src: url("../fonts/Avantt_Trial/AvanttTRIAL-Bold.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter Regular";
  src: url("../fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter Bold";
  src: url("../fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
