.ScheduleNextCheckup {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  /* justify-content: space-between; */
  max-width: 1440px;
  margin: 96px auto;
  font-family: "Avantt Regular";
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .ScheduleNextCheckup {
    font-size: 18px;
  }
}

.ScheduleNextCheckup-title,
.ScheduleNextCheckup-spaceBottom {
  margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
}

.ScheduleNextCheckup-subtitle {
  align-items: center;
  text-align: center;
  margin-bottom: 5vh;
  max-width: 80%;
  @media screen and (max-width: 768px) {
    margin-bottom: 10vh;
  }
}

.ScheduleNextCheckup-content {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
  padding: 0 10vw;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.ScheduleNextCheckup-inputList {
  list-style: none;
  padding: 16px 20px;
  margin: 16px 20px;
  max-width: 100%;
  min-width: 250px;
  margin-bottom: 28px;
  height: 43px;
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
}

/* Adjust the size and placement of the DatePicker's calendar icon
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  right: 20px;      // Adjust as needed
  top: 45%;
  transform: translateY(-50%);
  margin-left: 10px;
  height: 20px;    // Adjust as needed
  width: 20px;     // Adjust as needed
}
*/

/* .react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 35px;
} */

@media screen and (max-width: 768px) {
  .ScheduleNextCheckup-inputList {
    padding: 0 0;
  }
}

.ScheduleNextCheckup-buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 60%;
  margin-top: 5vh;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.ScheduleNextCheckup-margin {
  margin-left: 5%;
  margin-right: 5%;
  @media screen and (max-width: 768px) {
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}

.ScheduleNextCheckup div.popup-message {
  /* your popup styles can remain the same */
  position: absolute;
  top: calc(0% - 65px);
  left: 0%;
  background: white;
  border: 3px solid #053b47;
  border-radius: 20px;
  color: #053b47;
  padding: 10px 10px;
  margin-inline: auto;
  width: 100%;
  max-width: 400px;
  text-align: center;
}
