.test-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 25rem;
  width: 25rem;
  padding: 2rem;
  margin: 1rem;
  background: white;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* Makes the whole card act like a button */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.test-card.completed {
  background-color: white;
  opacity: 0.5; /* Adjust the opacity to make the card look "greyed out" */
  pointer-events: none; /* Prevent any interaction with the content */
}
/* Add hover effect for non-completed tests */
.test-card:not(.completed):hover {
  transform: scale(1.05); /* Scale up card slightly to highlight */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhance shadow */
  background-color: rgba(242, 168, 138, 0.8);
}

.test-card-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.test-card-time {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  font-size: 1.2rem;
  color: #666;
}
.test-card-image-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-card-image {
  margin-top: 20%;
  align-self: flex-start;
  font-size: 0.8rem;
  color: #666;
}
.test-card-image.depression {
  margin-top: 40%;
}
.test-card-image.stress {
  margin-top: 5%;
}
.test-card-image.self-esteem {
  margin-top: 30%;
}
.test-card-image.loneliness {
  margin-top: 30%;
}
.test-card-image.eating {
  margin-top: 0%; /* Adjust for specific image */
}
.test-card-image.adhd {
  margin-top: 50%;
}
.test-card-image.addiction {
  margin-top: 25%;
}

.test-card-bottom-section {
  position: absolute;
  bottom: 0%;

  h3 {
    color: #333;
    margin: 10px 0;
    font-size: 2rem;
  }
}

/* Make the paragraph invisible by default */
.test-card-bottom-section p {
  opacity: 0;
  transition: opacity 0.3s ease; /* Smooth transition for fading effect */
  font-size: 1rem;
  color: #666;
  height: 0; /* Collapse the space when not visible */
  overflow: hidden; /* Hide overflow when collapsed */
}

/* Make the paragraph visible on hover */
.test-card:hover .test-card-bottom-section p {
  opacity: 1;
  height: auto; /* Allow the paragraph to expand naturally */
}

/* Mobile styles */
@media screen and (max-width: 800px) {
  .test-card {
    min-height: 24rem; /* Adjust size for mobile */
    max-width: 20rem;
    width: 20rem; /* Take most of the screen width */
    padding: 1rem; /* Reduce padding */
    margin: 1rem; /* Reduce margin */
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
  .test-card-image-section {
    width: 20rem;
  }
  .test-card-image {
    margin-top: 10%;
  }
  .test-card-image.depression {
    margin-top: 15%;
  }
  .test-card-image.stress {
    margin-top: 10%;
  }
  .test-card-image.self-esteem {
    margin-top: 20%;
  }
  .test-card-image.loneliness {
    margin-top: 20%;
  }
  .test-card-image.adhd {
    margin-top: 30%;
  }
  .test-card-image.well-being {
    margin-top: 20%;
  }
  .test-card-image.anxiety {
    margin-top: 20%;
  }
  .test-card-image.eating {
    margin-top: 5%;
  }
  .test-card-image.addiction {
    margin-top: 20%;
  }
  .test-card-bottom-section {
    bottom: 0%;
    h3 {
      font-size: 1.5rem; /* Scale down the title */
    }

    p {
      font-size: 1rem; /* Scale down the paragraph */
    }
  }
  .test-card-bottom-section p {
    opacity: 1;
    height: auto;
  }

  /* Since hover effects don't apply on mobile in the same way, consider simplifying or removing them */
  .test-card:not(.completed):hover {
    transform: none; /* Remove scale effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Return to normal shadow */
  }
}
