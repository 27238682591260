.WellnessTestForm-marginTopBottom {
  margin: 32px 0;
}

.mobile-false {
  margin: 32px 0;
}

.mobile-true {
  display: none;
}

.WellnessTestForm-prompt {
  font-family: "Avantt Light";
  font-size: 30px;
  line-height: 1.3;
}

.WellnessTestForm-content {
  padding: 0 32px;
}

.WellnessTestForm-formHeader {
  font-weight: bold;
}

.WellnessTestForm-buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}
.WellnessTest-buttonContainer {
  margin-inline: auto;
  padding-top: 30px;
}
