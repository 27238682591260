@import "../../styles/variables";

.heart-rate-widget {
  border: 2px solid var(--primary); // Adjust color as needed
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  max-height: 300px;
  margin: 20px auto;
  background-color: #f9f9f9; // Light background color

  h2 {
    text-align: center;
    color: var(--primary); // Match the header color with the border
  }

  input[type="number"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    min-width: 20rem;
    margin-right: 2rem;
  }

  p {
    font-family: $avantt-regular;
    margin-top: 15px;
    text-align: center;
    font-size: 1.5rem;
  }
}
