.WellnessTestNew {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: auto;
  align-items: center;
  background-color: var(--primary);
  color: var(--background);
  flex-direction: column;
  padding-top: 10%;
  padding-bottom: 4rem;
  @media screen and (max-width: 900px) {
    height: auto;
  }
}

.WellnessTestNew-description {
  font-family: "Avantt Light";
  font-size: 30px;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 5%;
  @media screen and (max-width: 900px) {
    font-size: 24px;
    line-height: 1.2;
  }
}

.WellnessTestNew-prompt {
  font-family: "Avantt Light";
  font-size: 30px;
  line-height: 1.3;
  @media screen and (max-width: 900px) {
    font-size: 24px;
    line-height: 1.2;
  }
}

.WellnessTestNew-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  vertical-align: top;
  position: relative;
  margin-top: 30px;
  padding-top: 10px;
  background-color: var(--primary);
  color: var(--background);
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
}

.WellnessTestNew-testDetails {
  color: var(--background);
  font-size: 1.2rem !important;
}

.conversational-form {
  width: 100%;
}
