.ProgressFormInput {
  font-weight: normal;
  margin: 5% 0 32px;
  display: flex;
  justify-content: center;
}

.ProgressFormInput-question {
  font-family: "Avantt Bold";
  margin-bottom: 10px;
}

.ProgressFormInput-description {
  font-family: "Avantt Light";
  line-height: 1.2;
  margin-bottom: 10px;
}

.ProgressFormInput-optionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  font-family: "Avantt Regular";
  margin-top: 8px;
  padding: 0;
  gap: 16px;
  line-height: 1.3;
  @media screen and (max-width: 768px) {
    gap: 1rem;
    flex-wrap: wrap;
  }
}

.ProgressFormInput-option {
  flex: 1;
  position: relative;
  min-width: auto;
  max-width: 300px;
  @media screen and (max-width: 768px) {
    min-width: 150px;
  }
}

.ProgressFormInput-option.small {
  min-width: 50px;
  max-width: 50px;
}

.ProgressFormInput-label {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  margin-right: 1rem;
  height: 10rem;
  width: 15rem;
  border: 1px solid #ccc;
  border-radius: 10%;
  background-color: var(--background);
  color: var(--primary);
  cursor: pointer;
  transition: background 0.3s;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    font-size: 1.5rem;
    padding: 0.75rem 1.5rem;
    height: 8rem;
    width: 70%;
  }
}

.ProgressFormInput-radio {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.ProgressFormInput-button {
  display: none;
}

.ProgressFormInput-hidden-radio {
  opacity: 0;
  /* visibility: hidden; */
}

.ProgressFormInput-div {
  position: relative;
  @media screen and (max-width: 768px) {
    width: 40%;
    flex-wrap: wrap;
  }
}

.ProgressFormInput-radio:checked + .ProgressFormInput-label {
  background: var(--accent);
  color: var(--primary);
}
/*
.ProgressFormInput-radio:not(:checked):before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #2344b9;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;
  top: 0;
  cursor: pointer;
  background: white;
}

.ProgressFormInput-radio:checked:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #2344b9;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;
  background: #2344b9;
  top: 0;
  cursor: pointer;
} */
