@import "../../styles/variables";
@import "../../styles/theme";

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  font-family: $avantt-regular;
  font-size: 2rem;

  h2 {
    margin-bottom: 1.5rem;
  }

  .profile-group {
    display: flex;
    flex-direction: column;
    width: 100%; // Adjust width as needed
    max-width: 80%; // Adjust max-width as needed
    background-color: #f9f9f9; // Example background color
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0 0 0 2rem;
    border-radius: 10px;
  }

  .profile-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .section {
      margin-top: 2rem;
      margin-bottom: 2rem;
      font-family: $avantt-bold;
      font-size: 2.5rem;
    }

    .detail {
      margin-bottom: 10px;
      font-size: 1.5rem;

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; // Aligns the button to the right
    flex-grow: 1; // Allows this div to grow and take up necessary space for alignment
    // Ensure there's some margin or padding if needed to space it properly from the details
    margin-right: 3rem; // Example, adjust as needed
    margin-bottom: 2rem;
  }
}
