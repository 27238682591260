.WellnessGettingStarted {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  margin: 96px auto;
  font-family: "Avantt Regular";
  font-size: 24px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}
.WellnessGettingStarted-title,
.WellnessGettingStarted-spaceBottom {
  /* margin-bottom: 16px; */
  margin-bottom: 5vh;
}

.WellnessGettingStarted-content {
  padding: 0 10vw;
}

.WellnessGettingStarted-testList {
  list-style: none;
  padding: 16px;
  margin: 16px 0;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    padding: 0 0;
  }
}

.WellnessGettingStarted-testListItem {
  display: flex;
  flex-direction: row;
  padding: 8px 4px;
  font-weight: bold;
  margin-bottom: 8px;
}

.WellnessGettingStarted-testListItem > div {
  flex: 1;
  text-align: center;
}

.WellnessGettingStarted-testListItem > div:first-of-type {
  flex: 2;
  text-align: left;
  max-width: 40%;
}

.WellnessGettingStarted-testListItem > div:last-of-type {
  text-align: right;
  max-width: 32px;
}

.WellnessGettingStarted-statusIcon {
  width: 18px;
  height: 18px;
}

.WellnessGettingStarted-buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 400px;
  margin-top: 5vh;
}

.WellnessGettingStarted div.popup-message {
  position: absolute;
  top: 60px;
  left: 30%;
  background: white;
  border: 3px solid #053b47;
  border-radius: 20px;
  color: #053b47;
  padding: 10px 10px;
  margin-inline: auto;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.WellnessGettingStarted-new {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  font-family: "Avantt Regular";
  font-size: 24px;
  background-color: #f0ebe6;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}
.WellnessGettingStarted-content-new {
  width: 100%;
  max-width: 100%;
  /* margin: 96px auto; */
}
.WellnessGettingStarted-title-new {
  margin-top: 3rem;
  margin-bottom: 0rem;
  @media screen and (max-width: 768px) {
    margin-top: 10rem;
  }
}
.WellnessGettingStarted-spaceBottom-new {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: "Avantt Light";
  margin-top: 2rem;
  color: black;
}
.answersPrivate {
  font-size: 18px;
}
.WellnessGettingStarted-content-new {
  text-align: center;
  justify-content: center;
  align-items: center;
}
.WellnessGettingStarted-buttonContainer-new {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin-bottom: 3rem;
}
.WellnessGettingStarted-testList-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    padding: 0 0;
  }
}
.WellnessGettingStarted-testListItem-new {
  display: flex;
  flex-direction: row;
  padding: 8px 4px;
  font-weight: bold;
  margin-bottom: 8px;
}
