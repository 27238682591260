.WellnessTestScore {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
}

.WellnessTestScore-buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
}

.WellnessTestScore-scoreContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 16px;
}

.WellnessTestScore-scoreContainer > * {
  flex: 1;
}

@media screen and (max-width: 768px) {
  .WellnessTestScore-scoreContainer {
    flex-direction: column;
  }
}

.WellnessTestScore-scoreResultContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.WellnessTestScore-scoreResultProgressBackground {
  width: 10rem; /* Size of the circle */
  height: 10rem; /* Size of the circle */
  border-radius: 50%; /* Makes it round */
  background-color: var(--light-accent); /* The color of the circle */
  display: flex;
  justify-content: center;
  align-items: center;
}

/*.WellnessTestScore-scoreResultProgressBackgroundValue {*/
/*  width: 300px;*/
/*  aspect-ratio: 1;*/
/*  border: 5px solid #90CAF9;*/
/*  border-radius: 50%;*/
/*}*/

.WellnessTestScore-scoreResultProgressValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute; /* Absolutely position this on top of the background */
  width: 100%; /* Ensure it spans the entire width of its parent */
}

.WellnessTestScore-scoreResultNumber {
  font-size: 48px; /* Large font size for the score number */
  color: #ffffff; /* Color of the score number */
  font-weight: bold; /* Make the score number bold */
  line-height: 1; /* Ensure the line height matches the font size to center vertically */
}

.WellnessTestScore-scoreResultSubScale {
  margin-top: 2rem;
  color: var(--primary);
  font-size: 24px;
}

.WellnessTestScore-scoreLabelsList {
  list-style: none;
  margin: auto;
  padding: 0;
  width: 300px;
}

.WellnessTestScore-scoreLabelsListItem {
  margin-bottom: 16px;
  font-size: 1.5rem;
  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

.WellnessTestScore-scoreLabelTitle {
  font-family: "Avantt Bold";
  font-size: 2rem;
  margin-bottom: 8px;
}

.WellnessTestScore-scoreDescription {
  font-family: "Avantt Bold";
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 8px;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
.WellnessTestScore-scoreDescription > * {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
  margin-bottom: 2rem;
}
.WellnessTestScore-scoreDescription > *:nth-child(1) {
  animation-delay: 0.5s;
}
.WellnessTestScore-scoreDescription > *:nth-child(2) {
  animation-delay: 1.5s;
}
.WellnessTestScore-scoreDescription > *:nth-child(3) {
  animation-delay: 2.5s;
}
.WellnessTestScore-scoreDescription > *:nth-child(4) {
  animation-delay: 3.5s;
}
@keyframes fadeInUp {
 0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Base styling for the container */
.wellnessTest-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

/* Styling for the labels column */
.wellnessTest-labels {
  flex: 1; /* Adjust the ratio as per design requirement */
}

/* Styling for the labels list */
.wellnessTest-scoreLabelsList {
  list-style: none;
  padding: 0;
}

/* Styling for each label item */
.wellnessTest-scoreLabelsListItem {
  margin-bottom: 10px;
}

/* Styling for the label title */
.wellnessTest-scoreLabelTitle {
  font-weight: bold;
}

/* Styling for the score column */
.wellnessTest-score {
  flex: 2; /* Adjust the ratio as per design requirement */
  text-align: center;
}

/* Styling for the score background */
.wellnessTest-scoreResultProgressBackground {
  /* Additional styles for score background */
}

/* Styling for the buttons below the score */
.wellnessTest-scoreButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  gap: 2rem;
}

/* Styling for individual buttons */
.wellnessTest-button {
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  background-color: #007bff;
  border-radius: 5px;
}

/* Styling for the description column */
.wellnessTest-description {
  flex: 3; /* Adjust the ratio as per design requirement */
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .wellnessTest-container {
    flex-direction: column;
    align-items: center;
  }
  .wellnessTest-labels,
  .wellnessTest-score,
  .wellnessTest-description {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .wellnessTest-scoreButtons {
    flex-direction: column;
  }
}

.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}

