.contain {
    text-align: center;
    align-items: center;
    font-weight: bold;
    color: var(--background);
    font-size: 25px;
    padding-top: 30px;
  }
  .toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    text-align: left;
    top: 8px;
  }
  .checkbox {
    display: none;
  }
  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid var(--light);
    border-radius: 20px;
  }
  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 36px;
    padding: 0;
    line-height: 36px;
    color: var(--light);
    font-weight: bold;
    box-sizing: border-box;
  }
  .inner:before {
    content: "";
    padding-left: 10px;
    background-color: var(--primary);
    color: var(--light);
  }
  .inner:after {
    content: "";
    padding-right: 10px;
    background-color: var(--accent);
    color: var(--light);
    text-align: right;
  }
  .switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: var(--light);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid var(--light);
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .checkbox:checked + .label .inner {
    margin-left: 0;
  }
  .checkbox:checked + .label .switch {
    right: 0px;
  }
