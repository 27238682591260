.chat-section {
  position: relative;
  background: #ea5f26;
  padding-top: 1rem;
  padding-bottom: 5rem;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers the content vertically */
  justify-content: center;

  .centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Adjust as needed */
    width: 100%;
  }

  .chat-list-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 24rem;
    overflow-y: auto; // Enable vertical scrolling
    position: relative;
    mask-image: linear-gradient(
      to bottom,
      transparent,
      black 10%,
      black 90%,
      transparent
    ); // This creates the fading edges

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 10%; // This will control the size of the fuzzy effect
      z-index: 1;
      background: inherit; // This takes the background color of the .chat-section
    }

    &:before {
      top: 0;
      background: linear-gradient(to bottom, #ea5f26, transparent);
    }

    // &:after {
    //   bottom: 0;
    //   background: linear-gradient(to top, #f2b540, transparent);
    // }
  }

  .chat-list {
    list-style: none;
    gap: 3rem;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column; // Stack items vertically
    animation: none;
    width: 90%;
    margin-top: 0px;
    padding-top: 0px;

    li {
      flex-shrink: 0;
      white-space: normal;
      background: white;
      border-radius: 0px; // Adjust as necessary
      padding: 10px 15px; // Adjust as necessary for your layout
      color: black;
      font-weight: bold; // Bold text
      // Allow the background to fit the content width
      display: inline-block; // Make the list item inline-block to fit content width
      width: auto; // Width auto for natural content width
      word-wrap: wrap;
      margin-top: 0px;
      padding-top: 0px;
    }

    /* Define animations for left and right messages */
    @keyframes slideInFromLeft {
      from {
        transform: translateX(-100%);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @keyframes slideInFromRight {
      from {
        transform: translateX(100%);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .chat-message {
      // background: white;
      // padding: 10px 15px;
      // color: black;
      // font-weight: bold;
      // display: flex;
      // justify-content: space-between; /* Adjusts children with space between */
      // width: auto;
      // max-width: 80%;
      // margin-bottom: 1rem; /* Add space between messages */
      // opacity: 0; /* Default to invisible */
      // transition: opacity 0.5s ease; /* Smooth transition for the opacity */
      margin-top: 0;
      padding-top: 0;
      background: white;
      color: black;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      border-radius: 10px;
      word-wrap: break-word;
      width: max-content; /* Adjust width to content */
      max-width: 70%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add shadow for depth */
      transition: transform 1s, opacity 0.2s; /* Smooth transition for animations */
      opacity: 0; /* Start hidden */
    }
    .chat-message.active {
      opacity: 1; /* Active messages are fully visible */
      transform: translateY(0);
    }
    .chat-message.left {
      align-self: flex-start;
      background: #ffffff; /* Left messages in white */
      border-radius: 0px 10px 10px 10px;
      animation: none; /* Remove this if it was previously set */
    }

    .chat-message.right {
      align-self: flex-end;
      background: #f2b540; /* Right messages in your theme color */
      /* Ensure the logo appears before the text in document flow */
      flex-direction: row-reverse;
      border-radius: 10px 0px 10px 10px;
      animation: none; /* Remove this if it was previously set */
    }
    .chat-message.active.left {
      animation: slideInFromLeft 1s ease forwards;
    }

    .chat-message.active.right {
      animation: slideInFromRight 1s ease forwards;
    }
    @media (max-width: 768px) {
      .chat-message {
        padding: 0.5rem;
        max-width: 90%; /* Take up less space on small screens */
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      padding-top: 1rem;
      margin-top: 1rem;
    }
    .message-text {
      font-size: 1.5rem;
      line-height: 1.8rem;
      overflow-wrap: break-word;
      word-break: break-word; /* Ensures words can break and wrap to the next line */
      max-width: calc(
        100% - 4rem
      ); /* Adjusts the text width to allow space for the logo */
    }
    .logo {
      /* Adjust the logo styling here if necessary */
      padding-left: 10px; /* Add padding if the logo is on the left */
    }

    .profile-image {
      width: 4rem; /* Adjust to match your design */
      height: 4rem; /* Adjust to match your design */
      border-radius: 50%; /* Circular shape */
    }
  }

  .chat-title {
    font-size: 3rem;
    font-weight: bold;
    color: white; // Text color
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }

  .chat-description {
    font-size: 1.4rem;
    line-height: 2rem;
    color: white;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
  }

  @media (max-width: 768px) {
    .chat-section {
      padding-top: 0.5rem; /* Less vertical padding on smaller screens */
      padding-bottom: 1.5rem;
    }

    .chat-list-container {
      height: 15rem; /* Smaller fixed height for mobile devices */
    }

    .chat-message {
      padding: 5px 10px; /* Smaller padding inside chat messages */
      margin-bottom: 0.5rem; /* Reduce space between messages */
      font-size: 0.9rem; /* Smaller text within chat messages */
    }

    .chat-title,
    .chat-description {
      font-size: 1.2rem; /* Smaller font size for titles and descriptions */
      text-align: center; /* Ensure text is centered on smaller screens */
      padding: 0 1rem; /* Reduced side padding */
    }

    .message-text {
      font-size: 1rem; /* Adjust text size for better readability */
    }

    .logo,
    .profile-image {
      width: 2.5rem; /* Reduce logo and image size */
      height: 2.5rem;
    }
  }
}
