@import "../../styles/variables";
@import "../../styles/theme";

.Button {
  padding: 16px 28px;
  background: var(--primary);
  color: var(--light);
  font-weight: bold;
  // border-radius: 15px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  /* @media screen and (max-width: 800px) {
    padding: 8px 14px;
  } */
}

.Button-primary {
  background-color: var(--primary);
  color: var(--light);
  border: none;
  min-width: 200px;

  &:hover {
    // background-color: var(--accent) !important;
    // color: var(--primary) !important;
    background-color: #ea5f26 !important;
    color: white !important;
    transform: scale(1.1);
    &.white-hover {
      background: var(--light-accent) !important;
      color: var(--light) !important;
      transform: scale(1);
    }
  }
}
.Button-secondary {
  background-color: #cfcfcf;
  color: black !important;
  border: 1px solid var(--primary);
  min-width: 200px;

  &:hover {
    background-color: var(--primary) !important;
    color: white !important;
    transform: scale(1.1);
  }
}
.Button-tertiary {
  background-color: transparent !important;
  color: var(--primary) !important;
  border: 1px solid var(--primary);
  border-radius: 25px;
  padding: 16px 28px;
  font-weight: bold;
  min-width: none !important;

  &:hover {
    background-color: var(--primary) !important;
    color: white !important;
    transform: scale(1.1);
  }
}
.Button-custom {
  background-color: transparent !important;
  color: white !important;
  font-weight: normal;
  min-width: none !important;
}
.Button-nofill {
  background-color: transparent !important;
  padding: none !important;
  color: var(--primary) !important;
  &:hover {
    color: #ea5f26 !important;
  }
  &.no-padding {
    padding: 0 !important;
  }
  &.no-vertical-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.Button-highlighted {
  background-color: var(--accent) !important;
  padding: none;
  border: 1px solid var(--primary);
  color: var(--primary) !important;
}
.Button-secondary .join_now {
  color: var(--primary) !important;
}
.Button-disabled {
  color: white;
  border-color: #898989 !important;
  background-color: #898989 !important;
  cursor: not-allowed;
  &:hover {
    border-color: #898989 !important;
    background-color: #898989 !important;
    color: white !important;
    transform: scale(1);
  }
}
.border-radius {
  border-radius: 10px !important;
}
.narrow {
  min-width: 0px !important;
  padding: none !important;
}
.referral {
  background-color: #f0ebe6;
  border: none;
  color: #6a574d !important;
}
.standard-width {
  min-width: 200px;
}
.wide {
  min-width: 15rem;
}
.narrow {
  width: 12rem;
}
.auto-width {
  width: 10rem;
}
.short {
  padding: 0.75rem 4rem;
}
.no-border {
  border: none;
}
.with-border {
  border: 1px solid var(--primary);
}
.no-background-color {
  background-color: transparent !important;
  &:hover {
    background: var(--light-accent) !important;
    color: var(--light) !important;
    transform: scale(1.05);
  }
}
.bigger-font {
  font-size: 1.25rem;
}
.orange {
  background-color: #ea5f26 !important;
  border: #ea5f26 !important;
  color: white !important;
  &:hover {
    background: var(--light-accent) !important;
    color: var(--light) !important;
    transform: scale(1.05);
  }
}
.different-hover {
  &:hover {
    background: var(--light-accent) !important;
    color: var(--light) !important;
    transform: scale(1.05);
  }
}

.underline {
  text-decoration: underline;
}
.keep-font-size {
  font-size: inherit;
  margin-left: 0;
  padding-left: 0;
}
