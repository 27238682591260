.insights-section {
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
  position: relative;
  background-color: #b489ef;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  overflow: hidden; // Ensure that everything inside respects the border radius

  .insights-card-container {
    display: flex;
    justify-content: center;
    margin: 0 -80px; // Negative margins to allow cards to bleed over the sides
    overflow-x: auto; // Allow horizontal scrolling

    .card {
      flex: 0 0 auto; // Prevent flex items from growing or shrinking
      width: 20rem; // Fixed width for each card
      height: 16rem;
      gap: 3rem;
      margin: 0 20px; // Space between cards
      background-color: #d1b7f5; // Adjust color as needed
      color: #665780;
      font-size: 1.75rem;
      padding: 2rem;
      border-radius: 10px;
      text-align: center;
      position: relative; // Ensure they are above the summary
      z-index: 2;
      .activity-icon {
        margin-top: 1rem;
      }
      .text {
        margin-bottom: 3.5rem;
      }
    }
  }

  .summary {
    //   position: absolute; // Positioned over the container
    //   bottom: 50px; // Positioned at the bottom of the section, above cards overflow
    //   left: 0;
    //   width: 100%;
    //   text-align: center;
    //   z-index: 1; // Below the cards
    margin: 0; // Negative margins to allow cards to bleed over the sides
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
    margin-top: 1rem;

    h2 {
      font-size: 3rem;
      font-weight: bold;
      color: #3c315b;
      margin: 2rem 0;
    }

    p {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #3c315b;
    }
  }
}

@media (max-width: 768px) {
  .insights-section {
    .insights-card-container {
      margin: 0;
      flex-direction: column;
      align-items: center;
      overflow-x: hidden;
      padding-bottom: 20px; // Reduced extra space

      .card {
        width: 80%;
        margin: 10px 0; // Space between stacked cards
      }
    }

    .summary {
      position: relative; // Reset to flow naturally
      bottom: auto;
    }
  }
}
